import React, { useCallback, useState, useMemo } from 'react';
import { styled } from '@compiled/react';
import OriginTracing from '@atlassiansox/origin-tracing';
import Button from '@atlaskit/button';
import type { Placement } from '@atlaskit/popper';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { fg } from '@atlassian/jira-feature-gating';
import PremiumTrial from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/premium-trial';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	ReverseTrialExperimentPopupContent,
	useForceOpenPremiumPopupOnDay3,
} from '@atlassian/jira-reverse-trial';
import { useFetchOnboardingReverseTrial } from '@atlassian/jira-reverse-trial-utils';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types';
import { EDITION_AWARENESS, PACKAGE_NAME } from '../../../../common/constants';
import messages from './messages';
import type { BillingDetailsPopupProps } from './types';

const ReverseTrialExperimentErrorBoundary = ({ children }: { children: React.ReactNode }) => (
	<JSErrorBoundary
		id={EDITION_AWARENESS}
		packageName={PACKAGE_NAME}
		fallback="unmount"
		teamName="growth-tako"
	>
		{children}
	</JSErrorBoundary>
);

type PopupInnerProps = {
	url: string;
	defaultPopupInner: React.ReactElement;
	onFireUiAnalyticsEvent: (
		action: string,
		actionSubject: string,
		actionSubjectId: string,
		attributes?: Record<string, unknown>,
	) => void;
};

const PopupInner = ({ url, defaultPopupInner, onFireUiAnalyticsEvent }: PopupInnerProps) => {
	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();

	if (!isReady) {
		return null;
	}

	if (isReady && premiumTrialOnSignup) {
		return (
			<ReverseTrialExperimentPopupContent
				billingPageUrl={url}
				onFireUiAnalyticsEvent={onFireUiAnalyticsEvent}
			/>
		);
	}

	return defaultPopupInner;
};

const ReverseTrialExperimentForceOpen = ({
	onPopupToggle,
}: {
	onPopupToggle: (show: boolean, autoOpened?: boolean) => void;
}) => {
	const { premiumTrialOnSignup } = useFetchOnboardingReverseTrial();
	useForceOpenPremiumPopupOnDay3({
		shouldRun: premiumTrialOnSignup,
		onPopupToggle,
	});
	return null;
};

const PopupBackground = ({ defaultBackground }: { defaultBackground: React.ReactElement }) => {
	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();
	if (!isReady || premiumTrialOnSignup) {
		return null;
	}

	return defaultBackground;
};

export const BillingDetailsPopup = (props: BillingDetailsPopupProps) => {
	const {
		initialOpenState = false,
		edition,
		addBillingDetailsUrl,
		analyticsAttributes,
		children,
	} = props;

	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(initialOpenState);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();

	const popupTitle = formatMessage(
		edition === STANDARD_EDITION
			? messages.addPaymentDetailsPopupStandardTitle
			: messages.addPaymentDetailsPopupPremiumTitle,
	);
	const popupContent = formatMessage(
		edition === STANDARD_EDITION
			? messages.addPaymentDetailsPopupStandardContent
			: messages.addPaymentDetailsPopupPremiumContent,
	);

	const atlOrigin = useMemo(() => new OriginTracing({ product: 'jira' }), []);
	const url = atlOrigin.addToUrl(addBillingDetailsUrl);

	const fireUIAnalyticsEvent = useCallback(
		(
			action: string,
			actionSubject: string,
			actionSubjectId: string,
			attributes: Record<string, unknown> = {},
		) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action,
					actionSubject,
				}),
				actionSubjectId,
				{
					...analyticsAttributes,
					...attributes,
				},
			);
		},
		[analyticsAttributes, createAnalyticsEvent],
	);

	const onClickButton = useCallback(() => {
		fireUIAnalyticsEvent('clicked', 'button', 'paymentDetailsPopupItem', {
			...atlOrigin.toAnalyticsAttributes({ hasGeneratedId: true }),
		});
		setIsOpen(false);
	}, [atlOrigin, fireUIAnalyticsEvent]);

	const onPopupToggle = useCallback(
		// autoOpened is temporary for monitoring jsw_reverse_trials_feature_gate
		(show: boolean, autoOpened?: boolean) => {
			fireUIAnalyticsEvent('toggled', 'popup', 'editionAwarenessTrialPillPopup', {
				show,
				...(autoOpened !== undefined && { autoOpened }),
			});
			setIsOpen(show);
		},
		[fireUIAnalyticsEvent, setIsOpen],
	);

	const onTriggerClick = useCallback(() => {
		onPopupToggle(!isOpen);
	}, [onPopupToggle, isOpen]);

	const getPopupPlacement = (): Placement => {
		if (isReady && premiumTrialOnSignup) {
			return 'bottom-end';
		}

		return 'bottom-start';
	};

	const defaultPopupInner = (
		<>
			<Box as="h3" xcss={popupHeadingStyles}>
				{popupTitle}
			</Box>
			<Box as="p" xcss={popupContentStyles}>
				{popupContent}
			</Box>
			<Button appearance="primary" href={url} target="_blank" onClick={onClickButton}>
				{formatMessage(messages.addPaymentDetailsPopupButton)}
			</Button>
		</>
	);

	const defaultBackground = (
		<Background>
			<PremiumTrial alt="premium-trial-banner" />
		</Background>
	);

	const adjustAnalyticsAttributes = () => {
		// Checking for undefined specifically because if the user is not targeted, the value will be undefined
		if (isReady && premiumTrialOnSignup !== undefined) {
			return {
				...analyticsAttributes,
				isPremiumTrialOnSignup: premiumTrialOnSignup,
			};
		}

		return analyticsAttributes;
	};

	return (
		<>
			{fg('jsw_reverse_trials_feature_gate') && (
				<ReverseTrialExperimentErrorBoundary>
					<ReverseTrialExperimentForceOpen onPopupToggle={onPopupToggle} />
				</ReverseTrialExperimentErrorBoundary>
			)}
			<Popup
				isOpen={isOpen}
				onClose={() => onPopupToggle(false)}
				content={() => (
					<ContextualAnalyticsData sourceName="trialPillPopup" sourceType={SCREEN}>
						<FireScreenAnalytics attributes={adjustAnalyticsAttributes()} />
						{fg('jsw_reverse_trials_feature_gate') ? (
							<ReverseTrialExperimentErrorBoundary>
								<PopupBackground defaultBackground={defaultBackground} />
							</ReverseTrialExperimentErrorBoundary>
						) : (
							defaultBackground
						)}
						<PopupWrapper>
							{fg('jsw_reverse_trials_feature_gate') ? (
								<ReverseTrialExperimentErrorBoundary>
									<PopupInner
										url={url}
										defaultPopupInner={defaultPopupInner}
										onFireUiAnalyticsEvent={fireUIAnalyticsEvent}
									/>
								</ReverseTrialExperimentErrorBoundary>
							) : (
								defaultPopupInner
							)}
						</PopupWrapper>
					</ContextualAnalyticsData>
				)}
				placement={getPopupPlacement()}
				trigger={(triggerProps) => children({ triggerProps, onClick: onTriggerClick })}
			/>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupWrapper = styled.div({
	width: '270px',
	padding: token('space.250', '20px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Background = styled.div({
	display: 'flex',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	background: 'linear-gradient(45deg, #388BFF 0%, #0C66E4 51.23%, #8270DB 98.15%)',
});

const popupHeadingStyles = xcss({
	color: 'color.text',
	font: 'font.heading.medium',
	fontWeight: '700',
});

const popupContentStyles = xcss({
	marginTop: 'space.100',
	marginBottom: 'space.150',
	color: 'color.text.subtle',
	font: 'font.body.small',
	lineHeight: '14px',
});
