import {
	ROUTE_NAMES_LEGACY_GIN,
	ROUTE_NAMES_LEGACY_GIN_ISSUE,
	ROUTE_NAMES_LEGACY_GIN_QUICKSEARCH,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalIssueNavigatorRoute = {
	name: ROUTE_NAMES_LEGACY_GIN,
	path: '/issues/:issueKey(.*)?',
	apdexIgnoreParams: ['issueKey'],
};

export const globalIssueNavigatorBrowseRoute = {
	name: ROUTE_NAMES_LEGACY_GIN_ISSUE,
	path: '/browse/:issueKey?',
	query: ['jql|filter'],
};

export const globalIssueNavigatorRedirectRoute = {
	name: ROUTE_NAMES_LEGACY_GIN_QUICKSEARCH,
	path: '/secure/QuickSearch.jspa',
	query: ['searchString'],
};
