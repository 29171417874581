/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import NotificationIcon from '@atlaskit/icon/core/notification';
import PersonIcon from '@atlaskit/icon/core/person';
import AtlassianIcon from '@atlaskit/icon/glyph/emoji/atlassian';
import PersonCircleIcon from '@atlaskit/icon/glyph/person-circle';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { HeadingItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import {
	JIRA_SETTINGS_SECTION_IDS,
	IconWrapper,
	messages as commonMessages,
} from '@atlassian/jira-atlassian-navigation-global-settings-home';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAtlassianPersonalSettingsLink,
	testIdConcat,
} from '@atlassian/jira-navigation-apps-common';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { getGeneralPersonalSettingsPath } from '@atlassian/jira-personal-settings-paths';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current';
import { getGeneralPersonalSettingsRouteForLink } from '@atlassian/jira-router-routes-personal-settings-routes';
import { MenuItem } from '../../../../common/ui/menu/item';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list';
import messages from './messages';
import type { PersonalProps } from './types';

export const Personal = ({ testIdPrefix }: PersonalProps) => {
	const { data } = useCurrentUser();
	// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
	const email = data.user?.emailAddress;
	const atlassianPersonalSettingsLink = useAtlassianPersonalSettingsLink(email);
	const { formatMessage } = useIntl();

	const testId = testIdConcat(testIdPrefix, 'personal-settings');

	if (getWillShowNav4()) {
		return (
			<Section testId={testId}>
				<p css={sectionHeadingStyles} data-testid={testIdConcat(testId, 'heading')}>
					{formatMessage(commonMessages.personalJiraSettingsNonFinal)}
				</p>
				<MenuList>
					<MenuListItem ariaLabel={formatMessage(commonMessages.personalJiraSettingsNonFinal)}>
						<MenuItem
							analytics={{
								actionSubjectId: 'settingsNavigationMenuItem',
								menuItemId: 'personal-jira-settings',
								menuItemType: 'personalSettings',
								position: 0,
							}}
							description={formatMessage(messages.generalSettingsDescriptionNonFinal)}
							href={getGeneralPersonalSettingsPath()}
							iconBefore={<PersonIcon label="" color={token('color.icon')} />}
							testId={testIdConcat(testId, 'item-personal-jira-settings')}
							to={getGeneralPersonalSettingsRouteForLink()}
						>
							{formatMessage(messages.generalSettingsLabelNonFinal)}
						</MenuItem>
					</MenuListItem>
					<MenuListItem ariaLabel={formatMessage(commonMessages.personalJiraSettingsNonFinal)}>
						<MenuItem
							analytics={{
								actionSubjectId: 'settingsNavigationMenuItem',
								menuItemId: 'account-settings',
								menuItemType: 'personalSettings',
								position: 1,
							}}
							description={formatMessage(messages.notificationSettingsDescriptionNonFinal)}
							href="/jira/settings/personal/notifications"
							iconBefore={<NotificationIcon label="" color={token('color.icon')} />}
							testId={testIdConcat(testId, 'item-account-settings')}
						>
							{formatMessage(messages.notificationSettingsLabelNonFinal)}
						</MenuItem>
					</MenuListItem>
				</MenuList>
			</Section>
		);
	}

	return (
		<Section testId={testId}>
			<HeadingItem headingLevel={3} testId={testIdConcat(testId, 'heading')}>
				{formatMessage(commonMessages[JIRA_SETTINGS_SECTION_IDS.PERSONAL])}
			</HeadingItem>
			<MenuList>
				<MenuListItem ariaLabel={formatMessage(commonMessages[JIRA_SETTINGS_SECTION_IDS.PERSONAL])}>
					<MenuItem
						analytics={{
							actionSubjectId: 'settingsNavigationMenuItem',
							menuItemId: 'account-settings',
							menuItemType: 'personalSettings',
							position: 0,
						}}
						description={formatMessage(messages.accountSettingsDescription)}
						href={atlassianPersonalSettingsLink}
						iconBefore={
							<IconWrapper>
								{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
								<AtlassianIcon
									size="small"
									primaryColor={token('color.icon.inverse', '#FFFFFF')}
									label=""
								/>
							</IconWrapper>
						}
						iconAfter={
							// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
							<ShortcutIcon size="small" label="" />
						}
						target="_blank"
						testId={testIdConcat(testId, 'item-account-settings')}
					>
						{formatMessage(messages.accountSettings)}
					</MenuItem>
				</MenuListItem>
				<MenuListItem ariaLabel={formatMessage(commonMessages[JIRA_SETTINGS_SECTION_IDS.PERSONAL])}>
					<MenuItem
						analytics={{
							actionSubjectId: 'settingsNavigationMenuItem',
							menuItemId: 'personal-jira-settings',
							menuItemType: 'personalSettings',
							position: 1,
						}}
						description={formatMessage(messages.personalJiraSettingsDescription)}
						href={getGeneralPersonalSettingsPath()}
						iconBefore={
							<IconWrapper>
								{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
								<PersonCircleIcon
									size="small"
									primaryColor={token('color.icon.inverse', '#FFFFFF')}
									label=""
								/>
							</IconWrapper>
						}
						testId={testIdConcat(testId, 'item-personal-jira-settings')}
						to={getGeneralPersonalSettingsRouteForLink()}
					>
						{formatMessage(messages.personalJiraSettings)}
					</MenuItem>
				</MenuListItem>
			</MenuList>
		</Section>
	);
};

const sectionHeadingStyles = css({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest'),
	fontWeight: token('font.weight.semibold'),
	marginInlineStart: token('space.200'),
	marginTop: token('space.100'),
	marginBottom: token('space.075'),
});
