import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { createStore, createHook } from '@atlassian/react-sweet-state';
import {
	isJiraSoftwareOrJiraCorePremium,
	isSupportedProjectType,
	isTrialEndLockScreenEnabledForAnyProjectType,
} from '../utils';

export const LockScreenStore = createStore({
	initialState: {
		isLocked: false,
	},
	actions: {
		lockScreen:
			() =>
			({ setState }) =>
				setState({ isLocked: true }),
		unlockScreen:
			() =>
			({ setState }) =>
				setState({ isLocked: false }),
	},
	name: 'LockScreenExperiment',
});

export const useLockScreenController = createHook(LockScreenStore);

export const useIsScreenLocked = () => {
	const [lockScreenState] = useLockScreenController();
	const { loading: loadingProject, data: project } = useProject();
	const appEditions = useAppEditions();

	return (
		isTrialEndLockScreenEnabledForAnyProjectType(appEditions) &&
		!loadingProject &&
		isSupportedProjectType(project?.type) &&
		lockScreenState.isLocked
	);
};

export const useHideTopNavForLockScreen = () => {
	const [lockScreenState] = useLockScreenController();
	const { loading: loadingProject, data: project } = useProject();
	const appEditions = useAppEditions();
	const [expConfig] = UNSAFE_noExposureExp('jsw_premium_eot_lock_screen');

	if (expConfig.get('cohort', 'not-enrolled') === 'variation') {
		return (
			isTrialEndLockScreenEnabledForAnyProjectType(appEditions) &&
			!loadingProject &&
			isSupportedProjectType(project?.type) &&
			!isJiraSoftwareOrJiraCorePremium({ projectType: project?.type, appEditions }) &&
			lockScreenState.isLocked
		);
	}

	// same returned value as useIsScreenLocked
	return (
		isTrialEndLockScreenEnabledForAnyProjectType(appEditions) &&
		!loadingProject &&
		isSupportedProjectType(project?.type) &&
		lockScreenState.isLocked
	);
};
