import React from 'react';
import {
	ViewExperienceSuccessTracker,
	type TrackerWithoutCallbacksProps,
} from '@atlassian/jira-common-experience-tracking-viewing';
import { ExperienceSuccess } from '@atlassian/jira-experience-tracker';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { EXPERIENCE_NAVIGATION_TOP_MAIN } from '../../common/constants';
import type { NavigationSuccessTrackerProps } from './types';

const EmptyFragment = ({ children }: TrackerWithoutCallbacksProps) => <>{children}</>;

export const NavigationSuccessTracker = ({ children }: NavigationSuccessTrackerProps) => {
	const route = useCurrentRoute();

	const ViewExperienceSuccessTrackerComponent =
		route.isRedirect === true ? EmptyFragment : ViewExperienceSuccessTracker;

	return (
		<ViewExperienceSuccessTrackerComponent
			location="atlassian-navigation-main"
			parentProviders={null}
			failureEventAttributes={null}
		>
			{children}
			<ExperienceSuccess experience={EXPERIENCE_NAVIGATION_TOP_MAIN} />
		</ViewExperienceSuccessTrackerComponent>
	);
};
