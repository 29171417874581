import React, { useEffect } from 'react';
import OriginTracer from '@atlassiansox/origin-tracing';
import { graphql, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import Placeholder from '@atlassian/jira-placeholder';
import type { changePlansUrlProviderQuery } from '@atlassian/jira-relay/src/__generated__/changePlansUrlProviderQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { Props } from './types';

const Query = graphql`
	query changePlansUrlProviderQuery($cloudId: ID!, $productKey: String!, $offeringKey: ID!) {
		tenantContexts(cloudIds: [$cloudId]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement {
					experienceCapabilities {
						changeOffering(offeringKey: $offeringKey) {
							experienceUrl
						}
					}
				}
			}
		}
	}
`;

const ChangePlansUrlProviderInner = ({ children, queryReference }: Props) => {
	const data = usePreloadedQuery(Query, queryReference);

	const changeOfferingCapability =
		data?.tenantContexts?.[0]?.entitlementInfo?.entitlement?.experienceCapabilities?.changeOffering;
	const changeOfferingUrl = changeOfferingCapability?.experienceUrl;
	const origin = new OriginTracer({ product: 'jira' });
	const changeOfferingUrlWithAtlOrigin = changeOfferingUrl
		? origin.addToUrl(changeOfferingUrl)
		: null;

	return <>{children(changeOfferingUrlWithAtlOrigin)}</>;
};

export const ChangePlansUrlProvider = ({ children }: Omit<Props, 'queryReference'>) => {
	const cloudId = useCloudId();
	const [queryReference, loadQuery] = useQueryLoader<changePlansUrlProviderQuery>(Query);

	useEffect(() => {
		if (queryReference === null) {
			loadQuery({
				cloudId,
				productKey: 'jira-software.ondemand',
				offeringKey: 'de2887c9-8a55-41d5-b5cf-ad6a5589ebed',
			});
		}
	}, [cloudId, loadQuery, queryReference]);

	if (!queryReference) {
		return null;
	}

	return (
		<Placeholder name="reverse-trials-popup-change-plans" fallback={<Box xcss={skeletonStyles} />}>
			<ChangePlansUrlProviderInner queryReference={queryReference}>
				{children}
			</ChangePlansUrlProviderInner>
		</Placeholder>
	);
};

const skeletonStyles = xcss({
	height: '20px',
	backgroundColor: 'elevation.surface.hovered',
});
