import React, { type RefObject, useCallback, useMemo } from 'react';
import OriginTracing from '@atlassiansox/origin-tracing';
import CustomThemeButton from '@atlaskit/button/custom-theme-button';
import CreditCardIcon from '@atlaskit/icon/core/migration/credit-card--creditcard-filled';
import { Box, xcss } from '@atlaskit/primitives';
import { convertToJiraProjectType } from '@atlassian/jira-common-constants/src/project-types';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger';
import {
	fireJsmJpdAwarenessV2ExperimentExposure,
	fireJswAwarenessV2ExperimentExposure,
	isInVariationForJsmJpdTrialAwarenessV2,
	isInVariationForJswTrialAwarenessV2,
} from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-trial-modal';
import { editionAwarenessTrialModalEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-trial-modal/entrypoint';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SparkleIcon } from '@atlassian/jira-product-edition-upgrade';
import { getApplicationKeyForProject } from '@atlassian/jira-shared-types/src/application';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import { EATopNavPillTheme, IconWrapper } from '../../../../common/ui/styled';
import messages from './messages';
import type { TriggerButtonProps } from './types';

export const TriggerButton = ({
	trialDaysLeft,
	inGracePeriod,
	onClick,
	analyticsAttributes,
	jstoUrl,
	addBillingDetailsUrl,
	isEligibleForJsmJpdTrialAwarenessModalV2,
	isEligibleForJswTrialAwarenessModalV2,
	triggerProps = {},
	productKey,
	projectType,
	edition,
}: TriggerButtonProps) => {
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const atlOrigin = useMemo(() => new OriginTracing({ product: 'jira' }), []);
	const url =
		jstoUrl || inGracePeriod ? atlOrigin.addToUrl(jstoUrl ?? addBillingDetailsUrl) : undefined;

	const shouldShowJsmJpdTrialAwarenessModalV2 =
		isEligibleForJsmJpdTrialAwarenessModalV2 && isInVariationForJsmJpdTrialAwarenessV2();

	const shouldShowJswTrialAwarenessModalV2 =
		isEligibleForJswTrialAwarenessModalV2 && isInVariationForJswTrialAwarenessV2();

	const onClickButton = useCallback<Exclude<typeof onClick, undefined>>(
		(...args) => {
			if (isEligibleForJsmJpdTrialAwarenessModalV2) {
				fireJsmJpdAwarenessV2ExperimentExposure(createAnalyticsEvent, analyticsAttributes);
			} else if (isEligibleForJswTrialAwarenessModalV2) {
				fireJswAwarenessV2ExperimentExposure(createAnalyticsEvent, analyticsAttributes);
			}

			// This should always be either `not-enrolled` or `control` as variant should
			// not be running this code
			const cohort = expVal(
				'demonstrating_the_value_of_jira_premium_features',
				'cohort',
				'not-enrolled',
			);

			const target = jstoUrl ? 'JSTO' : 'BillingScreen';

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'editionAwarenessTrialPill',
				{
					premiumFeaturesCohort: cohort,
					...analyticsAttributes,
					...(jstoUrl || inGracePeriod
						? {
								target,
								...atlOrigin.toAnalyticsAttributes({ hasGeneratedId: true }),
							}
						: {}),
					...(isEligibleForJsmJpdTrialAwarenessModalV2
						? {
								jsmJpdTrialAwarenessModalV2: {
									shouldShowJsmJpdTrialAwarenessModalV2,
								},
							}
						: {}),
					...(isEligibleForJswTrialAwarenessModalV2
						? {
								jswTrialAwarenessModalV2: {
									shouldShowJswTrialAwarenessModalV2,
								},
							}
						: {}),
				},
			);

			return onClick && onClick(...args);
		},
		[
			isEligibleForJsmJpdTrialAwarenessModalV2,
			isEligibleForJswTrialAwarenessModalV2,
			jstoUrl,
			createAnalyticsEvent,
			analyticsAttributes,
			inGracePeriod,
			atlOrigin,
			onClick,
			shouldShowJsmJpdTrialAwarenessModalV2,
			shouldShowJswTrialAwarenessModalV2,
		],
	);

	const buttonText = inGracePeriod
		? formatMessage(messages.addPaymentDetailsSentenceCase)
		: formatMessage(messages.daysLeftOnTrialButtonSentenceCase, { trialDaysLeft });

	const shouldLinkToJstoPage =
		!shouldShowJsmJpdTrialAwarenessModalV2 && !shouldShowJswTrialAwarenessModalV2;

	const triggerButton = ({ ref }: { ref?: RefObject<HTMLElement> | EntryPointButtonTrigger }) => (
		// Ensures Tooltip positioned correctly on the button and is not lost
		<Box ref={triggerProps.ref} xcss={buttonWrapperStyles}>
			<CustomThemeButton
				{...triggerProps}
				iconBefore={
					<IconWrapper>
						{!inGracePeriod && <SparkleIcon label="" />}
						{inGracePeriod && <CreditCardIcon label="" color="currentColor" LEGACY_size="small" />}
					</IconWrapper>
				}
				onClick={onClickButton}
				theme={EATopNavPillTheme}
				href={shouldLinkToJstoPage ? url : undefined}
				ref={!shouldLinkToJstoPage ? ref : undefined}
				target="_blank"
			>
				<Box as="span" xcss={eaTopNavButtonTextStyles}>
					{buttonText}
				</Box>
			</CustomThemeButton>
		</Box>
	);

	const trialModalEntryPointParams = useMemo(
		() => ({
			cloudId,
			productKey,
			jiraProjectType: convertToJiraProjectType(projectType),
			jiraApplicationKey: getApplicationKeyForProject(projectType),
		}),
		[cloudId, productKey, projectType],
	);
	const trialModalEntryPointProps = useMemo(
		() => ({ projectType, edition }),
		[projectType, edition],
	);

	if (shouldShowJsmJpdTrialAwarenessModalV2 || shouldShowJswTrialAwarenessModalV2) {
		return (
			<ModalEntryPointPressableTrigger
				entryPoint={editionAwarenessTrialModalEntryPoint}
				entryPointParams={trialModalEntryPointParams}
				entryPointProps={trialModalEntryPointProps}
				interactionName="edition-awareness-trial-modal"
			>
				{triggerButton}
			</ModalEntryPointPressableTrigger>
		);
	}

	return triggerButton({ ref: undefined });
};

const buttonWrapperStyles = xcss({
	display: 'inline-flex',
});

const eaTopNavButtonTextStyles = xcss({
	font: 'font.body',
	color: 'color.text.accent.blue',
});
