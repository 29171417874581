import React, { type ComponentType } from 'react';
import {
	createStore,
	createHook,
	createActionsHook,
	createSelector,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { State } from '../../common/types';
import {
	getQueryHash,
	getWordCount,
	getExpandedCommandRegistryAnalytics,
	getRegistryData,
} from '../../common/utils';
import { actions, type Actions } from './actions';

const initialState: State = {
	commandPaletteSessionId: '',
	isOpen: false,
	isLoaded: false,
	search: '',
	activeIndex: 0,
	isSearchFieldFocused: false,
	commands: [],
	parentCommand: [],
	sections: [],
	isDisabled: false,

	registry: {},
	activeRegistry: {
		commandRegistry: {},
		registryIds: [],
	},
	onClosePendingCommandAction: undefined,

	isLoadChildrenError: false,
};

const store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'commandPalette',
});

export const useCommandPalette = createHook(store);
export const useActionsCommandPalette = createActionsHook(store);

export const withCommandPalette =
	<Props,>(ComponentToRender: ComponentType<Props>) =>
	(props: Props) => {
		const commandPalette = useCommandPalette();

		return <ComponentToRender {...props} commandPalette={commandPalette} />;
	};

// exported for testing
export const commandPaletteAnalyticsAttributesSelector = ({
	search,
	commandPaletteSessionId,
	expandedCommand,
	registry,
	activeRegistry,
}: State) => ({
	wordCount: getWordCount(search),
	queryLength: search.length,
	queryHash: getQueryHash(search),
	commandPaletteSessionId,
	cpMenu: expandedCommand?.id || 'rootMenu',
	...(getExpandedCommandRegistryAnalytics(registry, activeRegistry, expandedCommand?.id) || {}),
	activeRegistryIds: activeRegistry.registryIds,
	activeRegistryIdsLength: activeRegistry.registryIds.length,
	activeRegistryCommandsLength: Object.keys(activeRegistry.commandRegistry).length,
	relevancyModel: 'v2-string-similarity',
});

export const useCommandPaletteAnalyticsAttributes = createHook(store, {
	selector: commandPaletteAnalyticsAttributesSelector,
});

export const useCommandPaletteIsOpen = createHook(store, {
	selector: ({ isOpen }: State) => ({
		isOpen,
	}),
});

export const useCommandPaletteHasChildPopup = createStateHook(store, {
	selector: (state: State) => state.hasPopupOverlay,
});

export const useCommandPaletteIsLoaded = createHook(store, {
	selector: ({ isLoaded }: State) => ({
		isLoaded,
	}),
});

export const useCommandPaletteIsDisabled = createHook(store, {
	selector: ({ isDisabled }: State) => ({
		isDisabled,
	}),
});

export const useCommandPaletteSessionId = createHook(store, {
	selector: ({ commandPaletteSessionId }: State) => ({
		commandPaletteSessionId,
	}),
});

export const useCommandPaletteExpandedCommand = createHook(store, {
	selector: ({ expandedCommand, isOpen }: State) => ({
		expandedCommand,
		isOpen,
	}),
});

export const useCommandPaletteSearch = createHook(store, {
	selector: ({ search }: State) => ({ search }),
});

export const getCommandPaletteRegistryExpandedCommandRegistryData = createSelector(
	({ registry, activeRegistry, expandedCommand }: State) => ({
		registry,
		activeRegistry,
		expandedCommand,
	}),
	() => undefined,
	({ registry, activeRegistry, expandedCommand }) => {
		if (!expandedCommand) return undefined;

		const registryData = getRegistryData(registry, activeRegistry, expandedCommand.id);
		return {
			registryData,
			analyticsAttributes: registryData,
		};
	},
);
export const useCommandPaletteRegistryCurrentAttribution = createHook(store, {
	selector: getCommandPaletteRegistryExpandedCommandRegistryData,
});

export const useCommandPaletteUsePerformModeState = createHook(store, {
	selector: ({
		commands,
		search,
		expandedCommand,
		childResult,
		registry,
		activeRegistry,
	}: State) => ({
		commands,
		search,
		expandedCommand,
		childResult,
		registry,
		activeRegistry,
	}),
});
