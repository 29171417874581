import {
	CORE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
	SOFTWARE,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import type { SupportedApplication, SupportedProject } from './types';

export const getStartOfUTCDay = (timestamp: number) => new Date(timestamp).setUTCHours(0, 0, 0, 0);
export const getTrialDaysLeft = (trialEndTime?: number): number | undefined =>
	trialEndTime && Math.ceil((getStartOfUTCDay(trialEndTime) - Date.now()) / (24 * 3600 * 1000));

export const isInGracePeriod = (trialEndTime?: number): boolean => {
	const trialDaysLeft = getTrialDaysLeft(trialEndTime);

	// Technically we do not know whether user is in grace period or not, but if user is able to access the Jira with no payment details
	// and is trialEndTime is missing or over we assume that site is in grace period
	return !trialDaysLeft || trialDaysLeft < 0;
};

export const projectToApplicationKey = (project: SupportedProject): SupportedApplication => {
	switch (project) {
		// With spork, we should update core projects as it they were Software projects
		case SOFTWARE_PROJECT:
		case CORE_PROJECT:
			return SOFTWARE;
		case SERVICE_DESK_PROJECT:
			return SERVICE_DESK;
		case PRODUCT_DISCOVERY_PROJECT:
			return PRODUCT_DISCOVERY;
		default:
			throw new Error(`Unsupported project: ${project}`);
	}
};
