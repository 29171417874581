import fromPairs from 'lodash/fromPairs';
import { stringify } from 'query-string';
import fetchDefaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import { AuthenticationError } from '@atlassian/jira-standard-errors/src/common/utils/authentication-error/index.tsx';
import { PROJECT_TYPE, DASHBOARD_TYPE, FILTER_TYPE, PLAN_TYPE } from '../../constants';
import type { ItemType, FavouriteResponseItem, Item } from '../../types';

export type FavouriteQuery = {
	count: number;
	types: ItemType[];
};

const handleError = (error: AuthenticationError | Error) => {
	throw error;
};

// This is horrible but the API is wrong: the type returned is singular while
// everywhere else (even in add/remove) is plural
// @ts-expect-error - TS7031 - Binding element 'items' implicitly has an 'any' type.
const convertTypeToPlural = ({ items, ...rest }) => ({
	...rest,
	// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
	items: items.filter(Boolean).map((item) => ({
		...item,
		type: `${item.type}s`,
	})),
});

export const fetchFavoriteItems = async ({
	count,
	types = [PROJECT_TYPE, DASHBOARD_TYPE, FILTER_TYPE, PLAN_TYPE],
}: FavouriteQuery): Promise<FavouriteResponseItem[]> => {
	const query = stringify(fromPairs(types.map((type) => [type, count])));
	const url = `/rest/internal/2/favourites?${query}`;
	try {
		const response = await fetch(url, fetchDefaultOptions);
		if (!response.ok) {
			const errorCode = response.status;
			if (errorCode === 401) {
				return handleError(new AuthenticationError(`${errorCode}`));
			}
			// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string | undefined'.
			return handleError(new Error(errorCode));
		}
		const resp = await response.json();
		return resp.map(convertTypeToPlural);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return handleError(error);
	}
};

export const addToFavorite = async (item: Item) => {
	const url = '/rest/internal/2/favourites';
	try {
		const response = await fetch(url, {
			...fetchDefaultOptions,
			method: 'POST',
			body: JSON.stringify({
				entity: {
					id: item.id,
					type: item.type,
				},
				beforeEntityPosition: null,
			}),
		});

		if (!response.ok) {
			const errorCode = response.status;
			// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string | undefined'.
			return handleError(new Error(errorCode));
		}
		const result = await response.json();
		return result;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return handleError(error);
	}
};

export const removeFromFavorite = async (item: Item) => {
	const url = `/rest/internal/2/favourites/${item.type}/${item.id}`;
	try {
		const response = await fetch(url, { ...fetchDefaultOptions, method: 'DELETE' });
		if (!response.ok) {
			const errorCode = response.status;
			// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string | undefined'.
			return handleError(new Error(errorCode));
		}
		const result = await response.json();
		return result;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return handleError(error);
	}
};
