import React, { useCallback } from 'react';
import {
	CustomProductHome as AkCustomProductHomeDI,
	ProductHome as AkProductHomeDI,
} from '@atlaskit/atlassian-navigation';
import {
	JiraIcon,
	JiraLogo,
	JiraServiceManagementLogo,
	JiraServiceManagementIcon,
	JiraProductDiscoveryLogo,
	JiraProductDiscoveryIcon,
} from '@atlaskit/logo';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { NavLogo } from '@atlassian/jira-navigation-system/src/v4';
import { useRouterActions as useRouterActionsDI } from '@atlassian/jira-router';
import type { ProductName } from '@atlassian/jira-shared-types';
import { useProductName as useProductNameDI } from '@atlassian/jira-tenant-context-controller';
import { NAVIGATION_ITEM_ID } from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { useCustomLogo as useCustomLogoDI } from '../../controllers/custom-logo';
import { useNavigationItemAnalytics as useNavigationItemAnalyticsDI } from '../../controllers/navigation-item-analytics';
import { useSiteTitle as useSiteTitleDI } from '../../controllers/site-title';
import type { ProductHomeProps } from './types';

export const iconMap = {
	jira: { icon: JiraIcon, logo: JiraLogo },
	serviceDesk: { icon: JiraServiceManagementIcon, logo: JiraServiceManagementLogo },
	'product-discovery': {
		icon: JiraProductDiscoveryIcon,
		logo: JiraProductDiscoveryLogo,
	},
} as const;

const testIdPrefix = testIdGenerate('product-home-');

const getProductIconAndLogo = (productName: ProductName) => {
	if (productName === 'core' || productName === 'software') {
		return iconMap.jira;
	}

	return iconMap[productName];
};

const ProductHome = ({
	AkCustomProductHome = AkCustomProductHomeDI,
	AkProductHome = AkProductHomeDI,
	useCustomLogo = useCustomLogoDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	useProductName = useProductNameDI,
	useRouterActions = useRouterActionsDI,
	useSiteTitle = useSiteTitleDI,
}: ProductHomeProps) => {
	const logoUrl = useCustomLogo();
	const productName = useProductName();

	const siteTitleData = useSiteTitle();
	const siteTitle = (siteTitleData.shouldShow && siteTitleData.value) || undefined;

	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.LOGO,
	});

	const { push } = useRouterActions();
	const homeUrl = '/jira';
	const navigateToHome = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'ev' implicitly has an 'any' type.
		(ev) => {
			triggerAnalytics();
			if (ev.ctrlKey || ev.metaKey) return;
			ev.preventDefault();
			push(homeUrl);
		},
		[triggerAnalytics, push, homeUrl],
	);

	if (logoUrl) {
		return (
			// @ts-expect-error - TS2739 - Type '{ iconUrl: string; logoUrl: string; href: string; onClick: (ev: any) => void; siteTitle: string | undefined; testId: string | undefined; }' is missing the following properties from type 'CustomProductHomeProps': iconAlt, logoAlt
			<AkCustomProductHome
				iconUrl={logoUrl}
				logoUrl={logoUrl}
				href={homeUrl}
				onClick={navigateToHome}
				siteTitle={siteTitle}
				testId={testIdPrefix}
			/>
		);
	}

	if (getWillShowNav4()) {
		const { icon: MobileLogo, logo: Logo } = getProductIconAndLogo(productName);
		return (
			<NavLogo label="Jira" icon={MobileLogo} logo={Logo} href={homeUrl} onClick={navigateToHome} />
		);
	}

	const { icon, logo } = getProductIconAndLogo(productName);
	return (
		<AkProductHome
			icon={icon}
			logo={logo}
			href={homeUrl}
			onClick={navigateToHome}
			siteTitle={siteTitle}
			testId={testIdPrefix}
		/>
	);
};

const ProductHomeWithErrorBoundary = (props: ProductHomeProps) => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.HOME}>
		<ProductHome {...props} />
	</TopLevelErrorBoundary>
);

export { ProductHomeWithErrorBoundary as ProductHome };
