import React, { useCallback } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { Profile as AkProfileDI } from '@atlaskit/atlassian-navigation';
import AvatarDI from '@atlaskit/avatar';
import {
	SpotlightTarget as AkSpotlightTarget,
	// @ts-expect-error - TS2305 - Module '"@atlaskit/onboarding"' has no exported member 'SpotlightTargetProps'.
	type SpotlightTargetProps,
} from '@atlaskit/onboarding';
import PopupDI, { type TriggerProps } from '@atlaskit/popup';
import { useFetchUserTraitsOld, useUserTraits } from '@atlassian/jira-business-common';
import { useExperienceStart as useExperienceStartDI } from '@atlassian/jira-experience-tracker';
import { useIntlV2 as useIntlDI } from '@atlassian/jira-intl';
import {
	useTopMenus as useTopMenusDI,
	testIdConcat,
	testIdGenerate,
	MENU_ID,
} from '@atlassian/jira-navigation-apps-common';
import { NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { useHasNudgeActive, useNudgeStep } from '@atlassian/jira-onboarding-quickstart-core';
import { useCurrentUser as useCurrentUserDI } from '@atlassian/jira-platform-services-user-current';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next';
import {
	NavigationProfileNavItemWrapperNudgeAsync,
	NavigationProfileNavItemWrapperNudgeLazy,
} from '@atlassian/jira-software-onboarding-nudges--next/src/async';
import { LazyOpenMenuItem as LazyJswQuickstartOpenMenu } from '@atlassian/jira-software-onboarding-quickstart/src/async';
import {
	PROFILE_SPOTLIGHT_KEY,
	NAVIGATION_ITEM_ID,
	EXPERIENCE_NAVIGATION_TOP_MENU,
} from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { useNavigationItemAnalytics as useNavigationItemAnalyticsDI } from '../../controllers/navigation-item-analytics';
import { useUserBoardId as useUserBoardIdDI } from '../../controllers/user-board';
import { Menu as MenuDI } from './menu';
import messages from './messages';
import type { ProfileProps } from './types';

const SpotlightTargetDI = ({ children, ...props }: SpotlightTargetProps) => (
	<AkSpotlightTarget {...props}>
		{/*
		 * The atlaskit spotlight target component merely registers a ref on the child component,
		 *  so there is no way to give it a test id. We want to keep the test id as closely coupled
		 *  with the target as possible, so we add a span with id here, rather than on the child
		 *  where it could become more easily uncoupled.
		 */}
		<span data-test-id={`ak-spotlight-target-${props.name}`}>{children}</span>
	</AkSpotlightTarget>
);

const handleMouseEnter = () => {
	LazyJswQuickstartOpenMenu.preload();
	NavigationProfileNavItemWrapperNudgeLazy.preload();
};

const Profile = ({
	AkProfile = AkProfileDI,
	Avatar = AvatarDI,
	Menu = MenuDI,
	Popup = PopupDI,
	SpotlightTarget = SpotlightTargetDI,
	useCurrentUser = useCurrentUserDI,
	useExperienceStart = useExperienceStartDI,
	useIntl = useIntlDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	useTopMenus = useTopMenusDI,
	useUserBoardId = useUserBoardIdDI,
}: ProfileProps) => {
	const { formatMessage } = useIntl();
	const [isMenuOpen, { toggle: toggleMenu, off: closeMenu }] = useTopMenus(MENU_ID.PROFILE);
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.PROFILE,
	});
	const {
		data: { user },
	} = useCurrentUser();
	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.PROFILE,
		analyticsSource: 'atlassian-navigation',
	});

	const [nudgeStep] = useNudgeStep();
	const [hasNudgeActive] = useHasNudgeActive();

	const [userBoardId, userBoardOwnerAccountId] = useUserBoardId(isMenuOpen);
	const testIdPrefix = testIdGenerate('secondary-actions', 'profile');

	const [userTraits, { setUserTraits }] = useUserTraits();

	const fetchUserTraits = useFetchUserTraitsOld();

	const setUserTraitsIfNotAvailable = useCallback(async () => {
		if (user.isAnonymous) return;

		if (!userTraits?.attributes?.length && userTraits?.attributes !== null) {
			const newTraits = await fetchUserTraits();
			// We must explicitly check for false because fetchUserTraits returns "false" when pending, and "null" when traits are empty.
			if (newTraits !== false) {
				setUserTraits(newTraits);
			}
		}
	}, [user.isAnonymous, userTraits?.attributes, fetchUserTraits, setUserTraits]);

	const onClick = useCallback(() => {
		onStart();
		toggleMenu();
		triggerAnalytics();
	}, [onStart, toggleMenu, triggerAnalytics]);

	const content = useCallback(
		() => (
			<Menu
				userBoardId={userBoardId}
				userBoardOwnerAccountId={userBoardOwnerAccountId}
				testIdPrefix={testIdPrefix}
			/>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userBoardId, userBoardOwnerAccountId, testIdPrefix],
	);

	const Trigger = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			<div
				data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}
				onMouseOver={setUserTraitsIfNotAvailable}
				onFocus={setUserTraitsIfNotAvailable}
			>
				{/* Nudges are not rendered on the server, but they were imported, that added more weight to ssr */}
				<ConditionalNudgeWrapper
					conditionsToApplyWrapper={
						NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID === nudgeStep && hasNudgeActive
					}
					Wrapper={NavigationProfileNavItemWrapperNudgeAsync}
				>
					<AkProfile
						{...triggerProps}
						icon={
							// NOTE: This SpotlightTarget can be used throughout Jira not just within
							// the current package. They're often used for onboarding or changeboarding
							// experiences and allow an Atlaskit spotlight to be attached to page elements.
							// If you need to change this, make sure you check where the target ID is being used
							// and contact the relevant team as necessary. This Sourcegraph link should help :)
							// http://go/src/PROFILE_SPOTLIGHT_KEY
							<SpotlightTarget name={PROFILE_SPOTLIGHT_KEY}>
								<Avatar
									// @ts-expect-error - TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
									src={user.avatarUrls ? user.avatarUrls['48x48'] : undefined}
									size="small"
									borderColor="transparent"
								/>
							</SpotlightTarget>
						}
						isSelected={isMenuOpen}
						onClick={onClick}
						tooltip={formatMessage(messages.tooltip)}
						onMouseEnter={handleMouseEnter}
						aria-label={formatMessage(messages.tooltip)}
						id="atlassian-navigation.ui.profile.icon"
					/>
				</ConditionalNudgeWrapper>
				<EngagementSpotlight engagementId={PROFILE_SPOTLIGHT_KEY} />
			</div>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formatMessage, isMenuOpen, onClick, testIdPrefix, user, nudgeStep, hasNudgeActive],
	);

	if (user.isAnonymous) return null;

	if (__SERVER__) return <Trigger aria-expanded={false} aria-haspopup />;

	return (
		<Popup
			content={content}
			isOpen={isMenuOpen}
			onClose={closeMenu}
			placement="bottom-end"
			shouldRenderToParent
			trigger={Trigger}
			label={formatMessage(messages.tooltip)}
			role="menu"
		/>
	);
};

const ProfileWithErrorBoundary = (props: ProfileProps) => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.PROFILE}>
		<Profile {...props} />
	</TopLevelErrorBoundary>
);

export { ProfileWithErrorBoundary as Profile };
