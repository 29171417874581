import React, { type ReactNode } from 'react';
import { css, styled } from '@compiled/react';
import { MenuGroup } from '@atlaskit/menu';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';

export type MenuLayoutProps = {
	isWide?: boolean;
	isInteractionModelEnabled?: boolean;
	children: ReactNode;
};

export const MenuLayout = ({ children, isWide = false }: MenuLayoutProps) => (
	<Wrapper isWide={isWide}>
		<MenuGroup maxHeight={maxHeight}>{children}</MenuGroup>
	</Wrapper>
);

const starStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& a': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"[data-is-favorite='false'] button": {
			opacity: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"& :focus [data-is-favorite='false'] button, & [data-is-favorite='false'] button:focus": {
			opacity: 1,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"&:hover [data-is-favorite='false'] button": {
			opacity: 1,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{
	isWide?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isWide }) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		minWidth: isWide ? `${gridSize * 40}px` : `${gridSize * 29.25}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		maxWidth: `${gridSize * 100}px`,
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...starStyles,
});

const maxHeight = `calc(100vh - ${gridSize * 25}px)`;
