import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import differenceInDays from 'date-fns/differenceInDays';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import {
	InvitePeopleModalContextProvider,
	useInvitePeopleModal,
} from '@atlassian/invite-people/InvitePeopleModalContext';
import type { InvitePeopleProps, OnSubmitData } from '@atlassian/invite-people/types';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers';
import { inviteBusinessTeamsModalEntryPoint } from '@atlassian/jira-business-invite-business-teams-modal/entrypoint';
import { usePersonalizationProvider } from '@atlassian/jira-business-sample-project';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants';
import { CONTROL, NOT_ENROLLED, VARIATION } from '@atlassian/jira-common-util-get-experiment-group';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { ff, getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-flag';
import WarningFlag from '@atlassian/jira-flags/src/common/ui/components/warning-flag';
import { projectTypeToSubProduct } from '@atlassian/jira-invite-people-drawer';
import { useTopMenus, MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { useProjectSidebarNavigation } from '@atlassian/jira-navigation-apps-sidebar-common';
import { JWM_INVITE_TEAM_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { useIsTailoredOnboardingM2ForQuickstartEnabled } from '@atlassian/jira-onboarding-core/src/controllers/use-fetch-onboarding-personalisation';
import { isUserEnrolledInTailoredOnboardingExperiment } from '@atlassian/jira-onboarding-quickstart-core';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current';
import { useRouterActions } from '@atlassian/jira-router';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import { FREE_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types';
import { useRegisterNudgeTarget } from '@atlassian/jira-software-onboarding-nudges--next';
import { NavigationPeopleNavItemWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/async';
import { InviteTeamNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-invite-team-tour/async';
import { useHasOpened } from '@atlassian/jira-software-onboarding-quickstart-has-opened';
import {
	useFirstActivationDateMs,
	useIsAnonymous,
	useTenantContext,
} from '@atlassian/jira-tenant-context-controller';
import PeopleMenu from '@atlassian/people-menu';
import type { TeamCreateDialogProps } from '@atlassian/people-teams';
import { NAVIGATION_ITEM_ID, PACKAGE_NAME } from '../../../common/constants';
import { TopLevelErrorBoundary } from '../../../common/ui/error-boundary';
import { ChevronRightIcon } from '../../../common/ui/menu/trigger/main';
import { FullHeightTextColorWrapper } from '../../../common/ui/nudges/styled';
import { useFromSpotlightTour } from '../../../controllers/from-spotlight-tour';
import { useMenuHighlighted } from '../../../controllers/highlight';
import { useNavigationItemAnalytics } from '../../../controllers/navigation-item-analytics';
import {
	useBrowseUsersGroupsPermissions,
	useBrowseUsersGroupsPermissionsNext,
} from '../../../controllers/people';
import { useTPAExperiment } from '../../../controllers/team-product-access';
import { getInvitedJswUsers } from '../../../services/show-auto-upgraded-modal';

const getInviteThirdPartyTeamsExperiment = () =>
	getMultivariateFeatureFlag('confluence.frontend.growth.invite-3rd-party-teams', NOT_ENROLLED, [
		NOT_ENROLLED,
		CONTROL,
		VARIATION,
	]);

const STORAGE_KEY = 'jwm.invite-business-teams-modal-viwed-timestamp';
const SITE_USER_TRAIT_HAS_VIEW_EXPERIMENT = 'jira_screen_viewed_invitebusinessteamsmodal_first';
const STORAGE_PROVIDER_KEY = 'jira_invite-business-teams-modal';

export const localStorage = createLocalStorageProvider(STORAGE_PROVIDER_KEY);

const PeopleMenuDropdown = () => {
	const [inviteBusinessTeamsModalData, setInviteBusinessTeamsModalData] = useState({
		isOpen: false,
		userCounts: 0,
	});

	const [isMenuOpen, { off: closeMenu, on: openMenu }] = useTopMenus(MENU_ID.PEOPLE);
	const baseUrl = '';
	const { showFlag } = useFlagsService();
	const route = useCurrentRoute();
	const { push } = useRouterActions();
	const isHighlighted = useMenuHighlighted(MENU_ID.PEOPLE);
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.PEOPLE,
	});
	const [isEventFromSpotlightTour] = useFromSpotlightTour('spotlight');
	const checkUsersGroupPermission = useBrowseUsersGroupsPermissions();
	const checkUsersGroupPermissionNext = useBrowseUsersGroupsPermissionsNext(isMenuOpen);
	const isEnabledForTailoredOnboardingM2 = fg('jira_tailored_onboarding_jtbd_feature_gate')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsTailoredOnboardingM2ForQuickstartEnabled()
		: false;
	const addFlag = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'appearance' implicitly has an 'any' type.
		({ appearance, ...flagProps }) => {
			if (appearance === 'success' || appearance === 'normal') {
				// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
				showFlag((serviceProps) => <SuccessFlag {...flagProps} {...serviceProps} />);
			}

			if (appearance === 'warning') {
				// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
				showFlag((serviceProps) => <WarningFlag {...flagProps} {...serviceProps} />);
			}

			if (appearance === 'error') {
				// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
				showFlag((serviceProps) => <ErrorFlag {...flagProps} {...serviceProps} />);
			}
		},
		[showFlag],
	);
	// @ts-expect-error - TS7006 - Parameter 'url' implicitly has an 'any' type.
	const pushRoute = useCallback((url) => push(`${baseUrl}${url}`), [baseUrl, push]);
	const peopleMenuWrapperRef = useRegisterNudgeTarget<HTMLDivElement>(
		JWM_INVITE_TEAM_NUDGE_ID,
		true,
	).ref;

	const {
		data: { user },
	} = useCurrentUser();

	const projectTeamAccessData = useTPAExperiment();

	const teamCreateDialogProps = useMemo(() => {
		const dialogProps: Partial<TeamCreateDialogProps> = {
			currentUser: {
				id: user.accountId || '',
				fullName: user.userFullname,
				// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
				email: user.emailAddress !== undefined ? user.emailAddress : '',
				// @ts-expect-error - TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
				avatarUrl: user?.avatarUrls ? user.avatarUrls['16x16'] : '',
				nickname: user?.userFullname,
			},
			enableMembershipSettingsChoice: true,
			projectTeamAccess: projectTeamAccessData,
		};
		dialogProps.onClose = () => {
			if (peopleMenuWrapperRef?.current) {
				requestAnimationFrame(() => {
					peopleMenuWrapperRef?.current?.querySelector('button')?.focus();
				});
			}
		};
		return dialogProps;
		// the peopleMenuWrapperRef doesn't change as the condition if FG is static
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectTeamAccessData, user]);

	useEffect(() => {
		// close menu when transitioning to other pages.
		if (!fg('dont-auto-close-people-menu')) {
			closeMenu();
		}
		// close menu on unmount
		return () => {
			if (!fg('dont-auto-close-people-menu')) {
				closeMenu();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [route]);

	const { data: projectSidebarNavigationData } = useProjectSidebarNavigation();
	const subProduct = projectTypeToSubProduct(projectSidebarNavigationData?.projectType);

	const {
		cloudId,
		atlassianAccountId,
		appEditions: { software },
	} = useTenantContext();

	const { data: orgId } = useOrgId();

	const invitePeopleExperimentalFeatures: InvitePeopleProps['experimentalFeatures'] = useMemo(
		() => ({
			inviteThirdPartyTeams: getInviteThirdPartyTeamsExperiment(),
			displayInfiSpotlight: ff('confluence.frontend.growth.display-infi-spotlight'),
		}),
		[],
	);

	const continueUrl = window.location.href;

	const entryPoint = useEntryPoint(inviteBusinessTeamsModalEntryPoint, {});

	const [{ traits }] = usePersonalizationProvider();
	const firstActivationDateMs = useFirstActivationDateMs();
	const storedValue = localStorage.get(STORAGE_KEY);
	const isSoftwareProject = subProduct === SOFTWARE_PROJECT;
	const isValidAppEdition = software === FREE_EDITION || software === STANDARD_EDITION;
	const isValidSiteAge =
		firstActivationDateMs && differenceInDays(new Date(), firstActivationDateMs) > 28;
	const hasDismissedInviteBefore =
		storedValue || traits.some((trait) => trait.name === SITE_USER_TRAIT_HAS_VIEW_EXPERIMENT);

	const showModal = Boolean(
		isSoftwareProject && isValidAppEdition && isValidSiteAge && !hasDismissedInviteBefore,
	);

	const { openModal } = useInvitePeopleModal({
		continueUrl,
		enableThirdParty: true,
		experimentalFeatures: invitePeopleExperimentalFeatures,
		orgId,
		resourceAri: `ari:cloud:jira::site/${cloudId}`,
		showFlag: addFlag,
		source: 'peopleMenu',
		subProduct,
		experimental_applicationEdition: software,
		navigation: { directoryContextEntryPoint: '/jira' },
		onSubmit: async (data: OnSubmitData) => {
			const { inviteUsersResponse } = await data.defaultOnSubmit();

			// Handle invite business team modal experiment
			if (showModal) {
				const invitedJswUsers = getInvitedJswUsers(inviteUsersResponse);
				const invitedJswUserCount = invitedJswUsers.length;
				// make sure we only display our modal when invites are JSW
				if (invitedJswUserCount > 0) {
					setInviteBusinessTeamsModalData({
						isOpen: true,
						userCounts: invitedJswUserCount,
					});
					// Make sure user only see the dialog once, and avoid loading traits everytime when user invites a person
					localStorage.set(STORAGE_KEY, String(new Date()));
					entryPoint.entryPointActions.load();
				}
			}
		},
		// this is used to handle `add more people` button clicked, we need to hide invite business team modal
		...(showModal
			? {
					experimental_onReopenHandler: () => {
						openModal();
						setInviteBusinessTeamsModalData({
							isOpen: false,
							userCounts: 0,
						});
					},
				}
			: {}),
	});
	const invitePeopleHandlers = useMemo(
		() => ({
			handleClick: openModal,
		}),
		[openModal],
	);

	if (!atlassianAccountId) {
		return null;
	}

	const renderPeopleMenu = () => (
		<PeopleMenu
			shouldRenderToParent
			product="jira"
			cloudId={cloudId}
			userId={atlassianAccountId}
			isBrowseUsersEnabled={
				fg('dont-auto-close-people-menu')
					? checkUsersGroupPermissionNext
					: checkUsersGroupPermission
			}
			// allow PeopleMenu to do transitions to in-product People Directory
			pushRoute={pushRoute}
			isHighlighted={isHighlighted}
			// @ts-expect-error - TS2322 - Type '({ appearance, ...flagProps }: { [x: string]: any; appearance: any; }) => void' is not assignable to type '(flag: Flag) => void'.
			addFlag={addFlag}
			onClick={triggerAnalytics}
			// manage open state
			isOpen={isMenuOpen}
			onOpen={openMenu}
			onClose={isEventFromSpotlightTour ? noop : closeMenu}
			customChevronIcon={<ChevronRightIcon />}
			enablePreFetchingByHovering={false}
			subProduct={subProduct}
			isSSR={__SERVER__}
			orgId={orgId ?? undefined}
			teamCreateDialogProps={teamCreateDialogProps}
			invitePeopleHandlers={invitePeopleHandlers}
			isSectionList
		/>
	);

	return (
		<JSErrorBoundary
			id={MENU_ID.PEOPLE}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="navigation"
		>
			<PeopleMenuWrapper
				ref={peopleMenuWrapperRef}
				data-testid={`menu-${NAVIGATION_ITEM_ID.PEOPLE}-wrapper`}
			>
				{(isUserEnrolledInTailoredOnboardingExperiment() || isEnabledForTailoredOnboardingM2) && (
					<InviteTeamNudgeAsync />
				)}
				{fg('ttvc_global_navigation_improvement') ? (
					<PeopleMenu
						shouldRenderToParent
						product="jira"
						cloudId={cloudId}
						userId={atlassianAccountId}
						isBrowseUsersEnabled={
							fg('dont-auto-close-people-menu')
								? checkUsersGroupPermissionNext
								: checkUsersGroupPermission
						}
						// allow PeopleMenu to do transitions to in-product People Directory
						pushRoute={pushRoute}
						isHighlighted={isHighlighted}
						// @ts-expect-error - TS2322 - Type '({ appearance, ...flagProps }: { [x: string]: any; appearance: any; }) => void' is not assignable to type '(flag: Flag) => void'.
						addFlag={addFlag}
						onClick={triggerAnalytics}
						// manage open state
						isOpen={isMenuOpen}
						onOpen={openMenu}
						onClose={isEventFromSpotlightTour ? noop : closeMenu}
						customChevronIcon={<ChevronRightIcon />}
						enablePreFetchingByHovering={false}
						subProduct={subProduct}
						isSSR={__SERVER__}
						orgId={orgId ?? undefined}
						teamCreateDialogProps={teamCreateDialogProps}
						invitePeopleHandlers={invitePeopleHandlers}
						isSectionList
					/>
				) : (
					renderPeopleMenu()
				)}
			</PeopleMenuWrapper>
			{inviteBusinessTeamsModalData.isOpen && (
				<ModalEntryPointContainer
					entryPointReferenceSubject={entryPoint.entryPointReferenceSubject}
					id="invite-business-teams-modal"
					packageName="jira-business.invite-business-teams"
					teamName="jira-werewolves"
					runtimeProps={{
						userCounts: inviteBusinessTeamsModalData.userCounts,
						onClose: () => {
							setInviteBusinessTeamsModalData({
								isOpen: false,
								userCounts: 0,
							});
						},
					}}
					entryPointActions={entryPoint.entryPointActions}
				/>
			)}
		</JSErrorBoundary>
	);
};

const PeopleWithAuth = () => {
	const isAnonymous = useIsAnonymous();
	const { isVisible } = useOverflowStatus();
	const [{ hasOpened: shouldShowNudge }] = useHasOpened();

	if (isAnonymous) return null;

	if (!__SERVER__) {
		if (fg('ttvc_global_navigation_improvement')) {
			return (
				<JSErrorBoundary
					id="navigation.people-menu.with-nudgetooltip"
					packageName={PACKAGE_NAME}
					fallback="unmount"
					teamName="navigation"
				>
					{shouldShowNudge ? (
						<NavigationPeopleNavItemWrapperNudgeAsync isInsideMenu={!isVisible}>
							<FullHeightTextColorWrapper>
								<InvitePeopleModalContextProvider>
									<PeopleMenuDropdown />
								</InvitePeopleModalContextProvider>
							</FullHeightTextColorWrapper>
						</NavigationPeopleNavItemWrapperNudgeAsync>
					) : (
						<FullHeightTextColorWrapper>
							<InvitePeopleModalContextProvider>
								<PeopleMenuDropdown />
							</InvitePeopleModalContextProvider>
						</FullHeightTextColorWrapper>
					)}
				</JSErrorBoundary>
			);
		}
		return (
			<JSErrorBoundary
				id="navigation.people-menu.with-nudgetooltip"
				packageName={PACKAGE_NAME}
				fallback="unmount"
				teamName="navigation"
			>
				<NavigationPeopleNavItemWrapperNudgeAsync isInsideMenu={!isVisible}>
					<FullHeightTextColorWrapper>
						<InvitePeopleModalContextProvider>
							<PeopleMenuDropdown />
						</InvitePeopleModalContextProvider>
					</FullHeightTextColorWrapper>
				</NavigationPeopleNavItemWrapperNudgeAsync>
			</JSErrorBoundary>
		);
	}

	return <PeopleMenuDropdown />;
};

export const People = () => (
	<TopLevelErrorBoundary id={MENU_ID.PEOPLE}>
		<PeopleWithAuth />
	</TopLevelErrorBoundary>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PeopleMenuWrapper = styled.div({
	display: 'flex',
	height: '100%',
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		flexGrow: 1,
	},
});
