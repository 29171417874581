import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useGetBillingPageUrl } from '../get-billing-page-url';

type UseJswPremiumTrialUpgradeLink = { deepLink?: boolean; skip?: boolean; caller: string };

export const useJswPremiumTrialUpgradeLink = ({
	deepLink = true,
	skip = false,
	caller,
}: UseJswPremiumTrialUpgradeLink) => {
	const { productEntitlementDetails } = useTenantContext();
	const jswEntitlement = productEntitlementDetails?.[SOFTWARE];
	const isJswUsingCcp = jswEntitlement?.billingSourceSystem === 'CCP';
	const { data, ...rest } = useGetBillingPageUrl({
		isUsingCcp: isJswUsingCcp,
		editionToUpgradeTo: deepLink ? PREMIUM_EDITION : undefined,
		caller,
		skip,
		subProduct: SOFTWARE_PROJECT,
	});

	return {
		data: { premiumTrialUpgradeLink: data.billingPageUrl },
		...rest,
	};
};
