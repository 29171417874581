import React, { useEffect, useMemo, useState } from 'react';
import { NotificationLogClient } from '@atlaskit/notification-log-client';
import { useHelpPanel } from '@atlassian/jira-help-panel-services';
import { LazyNotifications as Notifications } from './lazy-badge';
import type { BadgeProps } from './types';

class MockNotificationLogClient extends NotificationLogClient {
	count = 0;

	constructor(countValue: number) {
		super('', '');
		this.count = countValue;
	}

	countUnseenNotifications() {
		return Promise.resolve({ count: this.count });
	}
}

const emptyClient = new MockNotificationLogClient(0);

export const useNotificationLogProvider = async (isNotificationCleared: boolean) => {
	const [{ releaseNotesNotifications }] = useHelpPanel();

	const notificationLogProvider = useMemo(
		() => new MockNotificationLogClient(releaseNotesNotifications),
		[releaseNotesNotifications],
	);

	const result = await Promise.resolve(
		isNotificationCleared || __SERVER__ || releaseNotesNotifications === 0
			? emptyClient
			: notificationLogProvider,
	);

	return result;
};

export const Badge = ({ isNotificationCleared }: BadgeProps) => {
	const [notifications, setNotifications] = useState(0);

	const notificationLogProvider = useNotificationLogProvider(isNotificationCleared);

	useEffect(() => {
		const initNotifications = async () => {
			const result = await notificationLogProvider;
			setNotifications(result.count);
		};

		initNotifications();
	}, [notificationLogProvider]);

	return notifications > 0 ? (
		<Notifications max={3} appearance="primary">
			{notifications}
		</Notifications>
	) : null;
};
