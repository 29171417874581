import React, { forwardRef, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useTopMenus, MENU_ID } from '@atlassian/jira-navigation-apps-common';
import {
	ADVANCED_ROADMAPS_TASK,
	useShouldShowNudge,
} from '@atlassian/jira-onboarding-quickstart-core';
import { usePlansItems } from '@atlassian/jira-plans-item-store';
import { getEdition, JIRA_SOFTWARE } from '@atlassian/jira-shared-types';
import { NavigationPlansNavItemWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/async';
import { useCanShowQuickstartNudge } from '@atlassian/jira-software-onboarding-nudges--next/src/controllers/quickstart-nudge';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller';
import {
	MenuTrigger as CommonMenuTrigger,
	type MenuTriggerProps,
} from '../../../common/ui/menu/trigger';
import { FullHeightTextColorWrapper } from '../../../common/ui/nudges/styled';

const WRAPPER_UID = 'plans-spotlight-target-wrapper';

const useDelayedSpotlight = () => {
	const [ready, setSpotlightReady] = useState(false);

	useLayoutEffect(() => {
		const timeoutId = setTimeout(() => setSpotlightReady(true), 100);
		return () => clearTimeout(timeoutId);
	}, []);

	return ready;
};

const useWrapper = () => {
	const ref = useRef<HTMLDivElement>(null);
	return {
		ref,
	};
};

const state = {
	hasSpotlightShown: false,
	hasSpotlightDone: false,
};
/**
 * The spotlight is forced closed on any click (outside of the menu), which is unexpected behavior
 * This hook will try to re-open the "More" dropdown when the "Plans" is unmounted until the user
 * clicks "Done" on the spotlight
 */
const useSpotlightForceOpen = () => {
	const { isVisible, openOverflowMenu } = useOverflowStatus();
	const isDropdownItem = !isVisible;
	useLayoutEffect(
		() => () => {
			const updateState = () => {
				/**
				 * The actual wrapper is supposed to be unmounted, so, if there is
				 * another wrapper element, it must be the cloned one on the spotlight
				 */
				const spotlightVisible = document.querySelector(`[data-uid="${WRAPPER_UID}"]`) != null;

				/**
				 * The dropdown menu is required to remaining openned after clicking Done on the
				 * spotlight. So openOverflowMenu() will be called until state.hasSpotlightDone is true.
				 */
				state.hasSpotlightShown = state.hasSpotlightShown || spotlightVisible;

				if (
					isDropdownItem &&
					spotlightVisible &&
					state.hasSpotlightShown &&
					!state.hasSpotlightDone
				) {
					openOverflowMenu();
				}

				if (state.hasSpotlightShown && !spotlightVisible) {
					state.hasSpotlightDone = true;
				}
			};
			queueMicrotask(updateState);
		},
		[isDropdownItem, openOverflowMenu],
	);
};

export const ConditionalNudgeWrapper = ({
	children,
	isVisible,
}: {
	children: JSX.Element;
	isVisible: boolean;
}) => {
	const shouldShowNudge = useCanShowQuickstartNudge();
	if (fg('ttvc_top_nav_plans_improvement')) {
		return shouldShowNudge && !__SERVER__ ? (
			<NavigationPlansNavItemWrapperNudgeAsync isInsideMenu={!isVisible}>
				<FullHeightTextColorWrapper>{children}</FullHeightTextColorWrapper>
			</NavigationPlansNavItemWrapperNudgeAsync>
		) : (
			<>{children}</>
		);
	}
	return !__SERVER__ ? (
		<NavigationPlansNavItemWrapperNudgeAsync isInsideMenu={!isVisible}>
			<FullHeightTextColorWrapper>{children}</FullHeightTextColorWrapper>
		</NavigationPlansNavItemWrapperNudgeAsync>
	) : (
		<>{children}</>
	);
};

const planOnboardingNudgeProps = !__SERVER__
	? {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		}
	: {};

export const MenuTrigger = forwardRef<HTMLElement, MenuTriggerProps>((props, triggerRef) => {
	const wrapper = useWrapper();
	const ready = useDelayedSpotlight();
	const appEditions = useAppEditions();
	const jswEdition = appEditions ? getEdition(JIRA_SOFTWARE, appEditions) : undefined;

	const [shouldShow] = useShouldShowNudge({
		itemNames: [ADVANCED_ROADMAPS_TASK],
	});

	useSpotlightForceOpen();
	const { isVisible } = useOverflowStatus();

	const [{ items }] = usePlansItems();

	const analyticsAttributes = {
		isAbleToCreateSamplePlan: !!items?.links?.createSamplePlan?.isVisible,
		...(jswEdition ? { jswEdition } : {}),
	};

	const [isMenuOpen, { toggle: toggleMenu }] = useTopMenus(MENU_ID.PLANS);

	useEffect(() => {
		if (shouldShow && !isMenuOpen) {
			toggleMenu();
		}
	}, [isMenuOpen, shouldShow, toggleMenu]);

	return (
		<Box backgroundColor="color.background.neutral.subtle" xcss={triggerWrapperStyles}>
			<SpotlightManager>
				{/* This spotlight is also used by the @atlassian/jira-business-onboarding-plans package,
                    do not clean up this spotlight without checking with the Wanjel team */}
				<SpotlightTarget name="nav-advanced-roadmaps-plans-spotlight">
					<Box
						data-uid={WRAPPER_UID}
						ref={wrapper.ref}
						backgroundColor="color.background.neutral.subtle"
						xcss={triggerWrapperStyles}
						// This ESLint suppressions is currently the ADS approved way of passing trigger props
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...planOnboardingNudgeProps}
					>
						<ConditionalNudgeWrapper isVisible={isVisible}>
							<CommonMenuTrigger
								ref={triggerRef}
								analyticsAttributes={analyticsAttributes}
								{...props}
							/>
						</ConditionalNudgeWrapper>
					</Box>
				</SpotlightTarget>
				{ready && <EngagementSpotlight engagementId="nav-advanced-roadmaps-plans-spotlight" />}
			</SpotlightManager>
		</Box>
	);
});

const triggerWrapperStyles = xcss({
	height: '100%',
	display: 'flex',
	flexGrow: 1,
});
