import React, { type ReactNode, useCallback, useEffect, useRef } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { lazy } from 'react-loosely-lazy';
import {
	SpotlightTarget as AkSpotlightTarget,
	// @ts-expect-error - TS2305 - Module '"@atlaskit/onboarding"' has no exported member 'SpotlightTargetProps'.
	type SpotlightTargetProps,
} from '@atlaskit/onboarding';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import {
	ViewExperienceSuccessTracker,
	ViewExperienceTrackingProvider,
} from '@atlassian/jira-common-experience-tracking-viewing';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	HELP_PANEL_ID,
	HELP_PANEL_WIDTH,
	HELP_PANEL_VERSION,
	ARTICLE_TYPE,
} from '@atlassian/jira-help-panel';
import { useHelpPanelActions } from '@atlassian/jira-help-panel-services';
import { useIntl } from '@atlassian/jira-intl';
import {
	useRightSidebarController,
	useActiveRightSidebarState as useRightSidebarState,
} from '@atlassian/jira-layout-controller';
import {
	useTopMenus,
	testIdConcat,
	testIdGenerate,
	MENU_ID,
} from '@atlassian/jira-navigation-apps-common';
import Placeholder from '@atlassian/jira-placeholder';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous';
import { useContainerContext } from '@atlassian/jira-providers-container-context';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import {
	NAVIGATION_ITEM_ID,
	HELP_SPOTLIGHT_KEY,
	EXPERIENCE_NAVIGATION_TOP_MENU,
} from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { Help as Trigger } from '../../common/ui/help-button';
import { useHelpButtonState } from '../../controllers/help-button';
import { useNavigationItemAnalytics } from '../../controllers/navigation-item-analytics';
import { Badge as SyncBadge, type BadgeProps } from './badge';
import { Menu } from './menu';
import messages from './messages';

export const SpotlightTarget = ({ children, ...props }: SpotlightTargetProps) => (
	<AkSpotlightTarget {...props}>
		{/*
		 * The atlaskit spotlight target component merely registers a ref on the child component,
		 *  so there is no way to give it a test id. We want to keep the test id as closely coupled
		 *  with the target as possible, so we add a span with id here, rather than on the child
		 *  where it could become more easily uncoupled.
		 */}
		<div data-test-id={`ak-spotlight-target-${props.name}`}>{children}</div>
	</AkSpotlightTarget>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyBadge = lazy(
	() =>
		import(/* webpackChunkName: "atlassian-navigation.async-notification-badge" */ './badge').then(
			(module) => module.Badge,
		),
	{ ssr: false },
);

const Badge = (props: BadgeProps) => {
	if (fg('ttvc_global_navigation_improvement')) {
		return <SyncBadge {...props} />;
	}
	return (
		<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.HELP_BADGE}>
			<Placeholder name="lazy-badge" fallback={null}>
				<LazyBadge {...props} />
			</Placeholder>
		</TopLevelErrorBoundary>
	);
};

const SpotlightTargetComponent = ({ children }: { children: ReactNode }) =>
	__SERVER__ ? (
		<>{children}</>
	) : (
		<>
			<SpotlightTarget name={HELP_SPOTLIGHT_KEY}>{children}</SpotlightTarget>
			{fg('add_engagement_spotlight_to_navbar_button') && (
				<EngagementSpotlight name={HELP_SPOTLIGHT_KEY} />
			)}
		</>
	);

const Help = () => {
	const [{ data: projectData }] = useContainerContext();
	const { formatMessage } = useIntl();
	const [isMenuOpen, { toggle: toggleMenu, off: closeMenu, on: openMenu }] = useTopMenus(
		MENU_ID.HELP,
	);
	const isAnonymous = useIsAnonymous();
	const [topMenusValue, { off: closeAllMenus }] = useTopMenus();
	const [, { setNavigationData }] = useHelpPanelActions();
	const { releaseNotesNotifications, setReleaseNotesNotifications } = useHelpButtonState();

	const isIPH = ff('iph.enabled');
	const helpButtonRef = useRef<HTMLElement>(null);
	let isProjectSimplified = false;
	if (projectData && 'project' in projectData && projectData.project) {
		isProjectSimplified = projectData.project.isSimplified;
	}

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const attributes:
		| {
				helpPanelVersion: string;
				helpPanelType: string;
				nextGenProject: boolean;
		  }
		| {
				helpPanelType: string;
				nextGenProject: boolean;
		  } = isIPH
		? {
				helpPanelVersion: HELP_PANEL_VERSION,
				helpPanelType: 'inProductHelp',
				nextGenProject: isProjectSimplified,
			}
		: {
				helpPanelType: 'dropdown',
				nextGenProject: isProjectSimplified,
			};

	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.HELP,
	});

	const { openRightSidebar, closeCurrentRightSidebar } = useRightSidebarController();
	const currentRightSidebarState = useRightSidebarState();
	const isHelpPanelOpenedPreviously = usePrevious(currentRightSidebarState);
	const testIdPrefix = testIdGenerate('secondary-actions', 'help');

	const content = useCallback(
		() => (
			<ViewExperienceTrackingProvider
				application={null}
				analyticsSource="atlassian-navigation"
				experience={EXPERIENCE_NAVIGATION_TOP_MENU}
				experienceId={NAVIGATION_ITEM_ID.HELP}
				edition={null}
			>
				<ViewExperienceSuccessTracker
					location="atlassian-navigation--secondary-actions--help--menu-popup"
					parentProviders={null}
					failureEventAttributes={null}
				>
					<Menu testIdPrefix={testIdPrefix} />
				</ViewExperienceSuccessTracker>
			</ViewExperienceTrackingProvider>
		),
		[testIdPrefix],
	);

	const onClick = useCallback(() => {
		if (isIPH) {
			if (
				currentRightSidebarState &&
				currentRightSidebarState.panelId === HELP_PANEL_ID &&
				!currentRightSidebarState.isCollapsed
			) {
				closeCurrentRightSidebar();
			} else {
				openRightSidebar(HELP_PANEL_ID, HELP_PANEL_WIDTH, false, true);
			}

			closeAllMenus();
			setNavigationData({ articleId: { id: '', type: ARTICLE_TYPE.HELP_ARTICLE }, history: [] });
		} else {
			toggleMenu();
		}

		// Shows if the help panel is currently open or closed
		const isPanelOpen =
			currentRightSidebarState?.panelId === HELP_PANEL_ID && !currentRightSidebarState?.isCollapsed;

		triggerAnalytics({
			...attributes,
			...(getBooleanFF('platform.navigation-help-opened-closed-indicator_b9xbt')
				? // Negating the current state of the panel to show if the panel is being opened or closed
					{ isOpened: !isPanelOpen }
				: {}),
		});
	}, [
		isIPH,
		triggerAnalytics,
		attributes,
		currentRightSidebarState,
		closeAllMenus,
		setNavigationData,
		closeCurrentRightSidebar,
		openRightSidebar,
		toggleMenu,
	]);

	const badge = useCallback(() => {
		if (isAnonymous) {
			return null;
		}
		return <Badge isNotificationCleared={releaseNotesNotifications === 0} />;
	}, [isAnonymous, releaseNotesNotifications]);

	const TriggerComponent = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			<div data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}>
				<SpotlightTargetComponent>
					<Trigger
						{...triggerProps}
						isSelected={isMenuOpen}
						badge={badge}
						onClick={onClick}
						tooltip={formatMessage(messages.tooltip)}
						label={formatMessage(messages.label)}
						ref={helpButtonRef}
					/>
				</SpotlightTargetComponent>
			</div>
		),
		[testIdPrefix, isMenuOpen, badge, onClick, formatMessage],
	);

	useEffect(() => {
		if (!currentRightSidebarState?.isCollapsed && releaseNotesNotifications !== 0) {
			setReleaseNotesNotifications(0);
		}
	}, [releaseNotesNotifications, setReleaseNotesNotifications, currentRightSidebarState]);

	useEffect(() => {
		if (isIPH) {
			if (
				currentRightSidebarState?.panelId === HELP_PANEL_ID &&
				!currentRightSidebarState?.isCollapsed
			) {
				openMenu();
			} else {
				closeMenu();
				if (isHelpPanelOpenedPreviously?.panelId === HELP_PANEL_ID && !currentRightSidebarState) {
					requestAnimationFrame(() => helpButtonRef.current?.focus());
				}
			}
		}
	}, [
		closeMenu,
		currentRightSidebarState,
		isHelpPanelOpenedPreviously,
		isIPH,
		openMenu,
		topMenusValue,
	]);

	if (__SERVER__ || isIPH) {
		return <TriggerComponent aria-expanded={false} aria-haspopup={!isIPH} />;
	}

	return (
		<Popup
			content={content}
			placement="bottom-end"
			isOpen={isMenuOpen}
			onClose={closeMenu}
			trigger={TriggerComponent}
		/>
	);
};

const HelpWithErrorBoundary = () => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.HELP}>
		<Help />
	</TopLevelErrorBoundary>
);

export { HelpWithErrorBoundary as Help };
