import React, { useCallback, useMemo } from 'react';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import FavoriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src';
import {
	ContextualAnalyticsData,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { FavoriteButtonProps } from './types';

export const FavoriteButton = ({
	// @ts-expect-error - TS2339 - Property 'beforeEntityId' does not exist on type 'FavoriteButtonProps'.
	beforeEntityId = undefined,
	// @ts-expect-error - TS2339 - Property 'beforeEntityType' does not exist on type 'FavoriteButtonProps'.
	beforeEntityType = undefined,
	id,
	isFavorite,
	favoriteItemName,
	isPending,
	menuItemType,
	onChangeFavorite,
	type,
}: FavoriteButtonProps) => {
	const { isVisible } = useOverflowStatus();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const contextualAnalyticsDataAttributes = useMemo(
		() => ({ isInMore: !isVisible, menuType: type, menuItemType }),
		[isVisible, menuItemType, type],
	);

	const onClick = useCallback(() => {
		const beforeEntity =
			beforeEntityId != null && beforeEntityType != null
				? {
						beforeEntity: {
							id: beforeEntityId,
							type: beforeEntityType,
						},
					}
				: undefined;
		onChangeFavorite({
			id,
			value: !isFavorite,
			type,
			...beforeEntity,
		});

		const actionSubject = type.slice(0, type.length - 1); // remove "s" from type ("projects" -> "project", etc)
		const action = isFavorite ? 'unstarred' : 'starred';
		const analyticsEvent = createAnalyticsEvent({});
		fireTrackAnalytics(analyticsEvent, `${actionSubject} ${action}`, id);
	}, [
		beforeEntityId,
		beforeEntityType,
		createAnalyticsEvent,
		id,
		isFavorite,
		onChangeFavorite,
		type,
	]);

	return (
		<ContextualAnalyticsData attributes={contextualAnalyticsDataAttributes}>
			<FavoriteButtonStateless
				isSmall
				isShownInList
				isFavorite={isFavorite}
				favoriteItemName={favoriteItemName}
				pending={isPending}
				onClick={onClick}
			/>
		</ContextualAnalyticsData>
	);
};
