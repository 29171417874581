/** @jsx jsx */
import React, { type FunctionComponent } from 'react';
import { css, jsx } from '@compiled/react';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { messages } from '../messages';

export const NotificationBellBothSplash: FunctionComponent = () => {
	const { formatMessage } = useIntl();

	return (
		<div
			css={BothSplashStyles}
			data-testid="third-party-nudge.ui.third-party-nudge-flag.splashes.notification-bell-splashes.both-splash"
		>
			<svg
				width="412"
				height="128"
				viewBox="0 0 412 128"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z"
					fill="url(#paint0_linear_2726_7335)"
				/>
				<g filter="url(#filter0_dd_2726_7335)">
					<rect x="47" y="18.0001" width="42" height="42" rx="4" fill="white" />
					<g clip-path="url(#clip0_2726_7335)">
						<path
							d="M68.42 34.6669C70.582 34.6669 72.3346 32.9692 72.3346 30.875C72.3346 28.7807 70.582 27.083 68.42 27.083C66.258 27.083 64.5053 28.7807 64.5053 30.875C64.5053 32.9692 66.258 34.6669 68.42 34.6669Z"
							fill="#7B83EB"
						/>
						<path
							opacity="0.1"
							d="M68.1404 31.6876H64.5948L64.6125 31.7632L64.6141 31.7697L64.6326 31.8396C64.8902 32.7859 65.5153 33.5992 66.3773 34.1098C67.2393 34.6203 68.2715 34.7885 69.2585 34.5793V32.7707C69.2577 32.4837 69.1396 32.2087 68.9301 32.0058C68.7206 31.8029 68.4367 31.6885 68.1404 31.6876Z"
							fill="black"
						/>
						<path
							opacity="0.2"
							d="M67.3021 32.5001H64.883C65.2005 33.1485 65.7014 33.6963 66.3275 34.0799C66.9537 34.4635 67.6793 34.6671 68.4202 34.6671V33.5832C68.4193 33.2962 68.3012 33.0212 68.0917 32.8183C67.8822 32.6154 67.5983 32.501 67.3021 32.5001Z"
							fill="black"
						/>
						<path
							d="M77.2273 34.9376C78.8487 34.9376 80.1631 33.6644 80.1631 32.0939C80.1631 30.5233 78.8487 29.2501 77.2273 29.2501C75.6059 29.2501 74.2915 30.5233 74.2915 32.0939C74.2915 33.6644 75.6059 34.9376 77.2273 34.9376Z"
							fill="#5059C9"
						/>
						<path
							d="M79.7439 35.7501H73.1501C73.0077 35.7501 72.8712 35.8049 72.7705 35.9024C72.6698 36 72.6133 36.1322 72.6133 36.2701V42.8595C72.5951 43.8132 72.9157 44.7439 73.5215 45.4959C74.1273 46.2479 74.9814 46.7755 75.9408 46.9903C76.5518 47.1145 77.1838 47.1051 77.7906 46.9629C78.3975 46.8206 78.9639 46.5491 79.4487 46.168C79.9336 45.787 80.3246 45.306 80.5933 44.76C80.862 44.2141 81.0016 43.617 81.002 43.0123V36.9689C81.002 36.6457 80.8695 36.3357 80.6335 36.1071C80.3976 35.8785 80.0775 35.7501 79.7439 35.7501Z"
							fill="#5059C9"
						/>
						<path
							d="M75.1305 36.9689V44.6876C75.1313 46.1181 74.6447 47.5088 73.7461 48.6439C72.8476 49.7791 71.5873 50.5952 70.1609 50.9656C68.7346 51.336 67.2219 51.24 65.8576 50.6925C64.4933 50.145 63.3537 49.1766 62.6157 47.9376C62.4543 47.6778 62.3141 47.4061 62.1963 47.1251C62.0866 46.8602 61.9941 46.5888 61.9195 46.3126C61.7813 45.7813 61.7108 45.2356 61.7098 44.6876V36.9689C61.7096 36.8088 61.742 36.6502 61.8052 36.5022C61.8683 36.3543 61.961 36.2198 62.0779 36.1066C62.1947 35.9934 62.3335 35.9036 62.4863 35.8425C62.639 35.7813 62.8027 35.7499 62.968 35.7501H73.8723C74.0376 35.7499 74.2013 35.7813 74.3541 35.8425C74.5068 35.9036 74.6456 35.9934 74.7625 36.1066C74.8794 36.2198 74.972 36.3543 75.0352 36.5022C75.0983 36.6502 75.1307 36.8088 75.1305 36.9689Z"
							fill="#7B83EB"
						/>
						<path
							opacity="0.2"
							d="M67.3021 32.5001H64.883C65.2005 33.1485 65.7014 33.6963 66.3275 34.0799C66.9537 34.4635 67.6793 34.6671 68.4202 34.6671V33.5832C68.4193 33.2962 68.3012 33.0212 68.0917 32.8183C67.8822 32.6154 67.5983 32.501 67.3021 32.5001Z"
							fill="black"
						/>
						<path
							opacity="0.1"
							d="M69.259 35.7501V46.0445C69.259 46.2894 69.1724 46.527 69.0137 46.7177C68.855 46.9084 68.6337 47.0407 68.3866 47.0926C68.3077 47.1154 68.2257 47.1263 68.1434 47.1251H62.1963C62.0866 46.8602 61.9941 46.5888 61.9195 46.3126C61.7813 45.7813 61.7108 45.2356 61.7098 44.6876V36.9689C61.7096 36.8088 61.742 36.6502 61.8052 36.5022C61.8683 36.3543 61.961 36.2198 62.0779 36.1066C62.1947 35.9934 62.3335 35.9036 62.4863 35.8425C62.639 35.7813 62.8027 35.7499 62.968 35.7501H69.259Z"
							fill="black"
						/>
						<path
							opacity="0.2"
							d="M68.4202 35.7501V46.857C68.4214 46.9367 68.4101 47.0162 68.3866 47.0926C68.333 47.332 68.1964 47.5464 67.9995 47.7001C67.8027 47.8538 67.5574 47.9376 67.3046 47.9376H62.6157C62.4543 47.6778 62.3141 47.4061 62.1963 47.1251C62.0866 46.8602 61.9941 46.5888 61.9195 46.3126C61.7813 45.7813 61.7108 45.2356 61.7098 44.6876V36.9689C61.7096 36.8088 61.742 36.6502 61.8052 36.5022C61.8683 36.3543 61.961 36.2198 62.0779 36.1066C62.1947 35.9934 62.3335 35.9036 62.4863 35.8425C62.639 35.7813 62.8027 35.7499 62.968 35.7501H68.4202Z"
							fill="black"
						/>
						<path
							opacity="0.2"
							d="M68.4202 35.7501V45.232C68.4189 45.5182 68.3009 45.7923 68.092 45.9947C67.883 46.1971 67.6001 46.3114 67.3046 46.3126H61.9195C61.7813 45.7813 61.7108 45.2356 61.7098 44.6876V36.9689C61.7096 36.8088 61.742 36.6502 61.8052 36.5022C61.8683 36.3543 61.961 36.2198 62.0779 36.1066C62.1947 35.9934 62.3335 35.9036 62.4863 35.8425C62.639 35.7813 62.8027 35.7499 62.968 35.7501H68.4202Z"
							fill="black"
						/>
						<path
							opacity="0.2"
							d="M62.968 35.7501C62.8027 35.7499 62.639 35.7813 62.4863 35.8425C62.3335 35.9036 62.1947 35.9934 62.0779 36.1066C61.961 36.2198 61.8683 36.3543 61.8052 36.5022C61.742 36.6502 61.7096 36.8088 61.7098 36.9689V44.6876C61.7108 45.2356 61.7813 45.7813 61.9195 46.3126H66.4658C66.7613 46.3114 67.0442 46.1971 67.2532 45.9947C67.4621 45.7923 67.5801 45.5182 67.5814 45.232V35.7501H62.968Z"
							fill="black"
						/>
						<path
							d="M55.2787 32.5001H66.4632C66.7597 32.5001 67.0441 32.6142 67.2538 32.8174C67.4635 33.0205 67.5813 33.296 67.5813 33.5832V44.4171C67.5813 44.7043 67.4635 44.9798 67.2538 45.1829C67.0441 45.386 66.7597 45.5001 66.4632 45.5001H55.2787C54.9822 45.5001 54.6978 45.386 54.4881 45.1829C54.2784 44.9798 54.1606 44.7043 54.1606 44.4171V33.5832C54.1606 33.296 54.2784 33.0205 54.4881 32.8174C54.6978 32.6142 54.9822 32.5001 55.2787 32.5001Z"
							fill="#4B53BC"
						/>
						<path
							d="M64.2092 36.5421H61.7012V43.0583H60.0555V36.5421H57.5324V34.9414H64.2092V36.5421Z"
							fill="white"
						/>
					</g>
					<rect x="47" y="68.0001" width="42" height="42" rx="3.18898" fill="white" />
					<path
						d="M60.4623 92.4303C60.4623 93.9336 59.2343 95.1616 57.7311 95.1616C56.2278 95.1616 54.9998 93.9336 54.9998 92.4303C54.9998 90.9271 56.2278 89.6991 57.7311 89.6991H60.4623V92.4303Z"
						fill="#E01E5A"
					/>
					<path
						d="M61.8377 92.4304C61.8377 90.9271 63.0657 89.6991 64.569 89.6991C66.0722 89.6991 67.3002 90.9271 67.3002 92.4304V99.2691C67.3002 100.772 66.0722 102 64.569 102C63.0657 102 61.8377 100.772 61.8377 99.2691V92.4304Z"
						fill="#E01E5A"
					/>
					<path
						d="M64.569 81.4625C63.0658 81.4625 61.8378 80.2345 61.8378 78.7312C61.8378 77.228 63.0658 76 64.569 76C66.0723 76 67.3003 77.228 67.3003 78.7312V81.4625H64.569Z"
						fill="#36C5F0"
					/>
					<path
						d="M64.5698 82.8386C66.0731 82.8386 67.3011 84.0666 67.3011 85.5699C67.3011 87.0731 66.0731 88.3011 64.5698 88.3011H57.7311C56.2278 88.3011 54.9998 87.0731 54.9998 85.5699C54.9998 84.0666 56.2278 82.8386 57.7311 82.8386H64.5698Z"
						fill="#36C5F0"
					/>
					<path
						d="M75.5365 85.5698C75.5365 84.0666 76.7645 82.8386 78.2678 82.8386C79.771 82.8386 80.999 84.0666 80.999 85.5698C80.999 87.0731 79.771 88.3011 78.2678 88.3011H75.5365V85.5698Z"
						fill="#2EB67D"
					/>
					<path
						d="M74.1611 85.57C74.1611 87.0732 72.9331 88.3012 71.4299 88.3012C69.9266 88.3012 68.6986 87.0732 68.6986 85.57V78.7312C68.6986 77.228 69.9266 76 71.4299 76C72.9331 76 74.1611 77.228 74.1611 78.7312V85.57Z"
						fill="#2EB67D"
					/>
					<path
						d="M71.4297 96.538C72.933 96.538 74.161 97.766 74.161 99.2692C74.161 100.772 72.933 102 71.4297 102C69.9265 102 68.6985 100.772 68.6985 99.2692V96.538H71.4297Z"
						fill="#ECB22E"
					/>
					<path
						d="M71.4299 95.1616C69.9266 95.1616 68.6986 93.9336 68.6986 92.4303C68.6986 90.9271 69.9266 89.6991 71.4299 89.6991H78.2686C79.7719 89.6991 80.9999 90.9271 80.9999 92.4303C80.9999 93.9336 79.7719 95.1616 78.2686 95.1616H71.4299Z"
						fill="#ECB22E"
					/>
					<path
						d="M101 28.0001C101 25.791 102.791 24.0001 105 24.0001H361C363.209 24.0001 365 25.791 365 28.0001V100C365 102.209 363.209 104 361 104H105C102.791 104 101 102.209 101 100V28.0001Z"
						fill="white"
						fill-opacity="0.98"
					/>
					<text
						fill="#091E42"
						xmlSpace="preserve"
						fontSize="9"
						fontWeight="600"
						letterSpacing="0em"
					>
						<tspan x="113" y="45.1993">
							<FormattedMessage
								{...messages.bothNudgeTitle}
								values={{
									tag: (chunks) => (
										<tspan
											fill="#0055CC"
											xmlSpace="preserve"
											fontSize="9"
											fontWeight="600"
											letterSpacing="0em"
										>
											{chunks}
										</tspan>
									),
								}}
							/>
						</tspan>
					</text>
					<rect x="113" y="54.0001" width="210" height="6" rx="3" fill="#DCDFE4" />
					<rect x="113" y="66.0001" width="91" height="6" rx="3" fill="#DCDFE4" />
					<rect x="207" y="66.0001" width="91" height="6" rx="3" fill="#DCDFE4" />
					<rect
						x="113.3"
						y="80.3001"
						width="61.9924"
						height="15.4"
						rx="1.48113"
						stroke="#B3B9C4"
						stroke-width="0.6"
					/>
					<text
						fill="#626F86"
						xmlSpace="preserve"
						white-space="pre"
						font-size="9"
						letter-spacing="0px"
					>
						<tspan x="123.26" y="91.1994">
							{formatMessage(messages.nudgeCommentButton)}
						</tspan>
					</text>
					<rect
						x="181.892"
						y="80.3001"
						width="60.9924"
						height="15.4"
						rx="1.48113"
						stroke="#B3B9C4"
						stroke-width="0.6"
					/>
					<text
						fill="#626F86"
						xmlSpace="preserve"
						white-space="pre"
						font-size="9"
						letter-spacing="0px"
					>
						<tspan x="191.414" y="91.1994">
							{formatMessage(messages.nudgeEditIssueButton)}
						</tspan>
					</text>
					<rect
						x="249.485"
						y="80.3001"
						width="70.9924"
						height="15.4"
						rx="1.48113"
						stroke="#B3B9C4"
						stroke-width="0.6"
					/>
					<text
						fill="#626F86"
						xmlSpace="preserve"
						white-space="pre"
						font-size="9"
						letter-spacing="0px"
					>
						<tspan x="259.007" y="91.1994">
							{formatMessage(messages.nudgeOpenInJiraButton)}
						</tspan>
					</text>
				</g>
				<defs>
					<filter
						id="filter0_dd_2726_7335"
						x="39"
						y="10"
						width="334"
						height="108"
						filterUnits="userSpaceOnUse"
						color-interpolation-filters="sRGB"
					>
						<feFlood flood-opacity="0" result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset />
						<feGaussianBlur stdDeviation="4" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.16 0"
						/>
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2726_7335" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset />
						<feGaussianBlur stdDeviation="0.5" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.12 0"
						/>
						<feBlend
							mode="normal"
							in2="effect1_dropShadow_2726_7335"
							result="effect2_dropShadow_2726_7335"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect2_dropShadow_2726_7335"
							result="shape"
						/>
					</filter>
					<linearGradient
						id="paint0_linear_2726_7335"
						x1="408.5"
						y1="3.50001"
						x2="0.106745"
						y2="128.349"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0.0974484" stop-color="#B4E3FF" />
						<stop offset="0.920861" stop-color="#F6F7FF" />
					</linearGradient>
					<clipPath id="clip0_2726_7335">
						<rect width="27.3" height="26" fill="white" transform="translate(54 26.0001)" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

const BothSplashStyles = css({ height: 128 });
