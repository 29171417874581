import {
	ROUTE_NAMES_MARKETPLACE_ADDONS_EMCEE,
	ROUTE_NAMES_MARKETPLACE_MANAGE_APPS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const emceeAppRoute = {
	name: ROUTE_NAMES_MARKETPLACE_ADDONS_EMCEE,
	path: '/plugins/servlet/ac/com.atlassian.jira.emcee:environment(.?[\\w.]*)?/:page',
};
export const manageAppsRoute = {
	name: ROUTE_NAMES_MARKETPLACE_MANAGE_APPS,
	path: '/plugins/servlet/upm',
};
