import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useResource } from '@atlassian/jira-router';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
import {
	GIC_MINI_MODAL_DISCOVERABILITY_PREFERENCE_KEY,
	GIC_PERSISTENT_STATE_PREFERENCE_KEY,
} from '../common/constants';
import { persistentIssueCreateModalViewResource } from './resource';
import type { PersistentIssueCreateModalView } from './types';

export const usePersistentIssueCreateModalViewResource = () => {
	const { data, update } = useResource(persistentIssueCreateModalViewResource);

	const updateView = useCallback(
		async (view: PersistentIssueCreateModalView) => {
			try {
				await userPreferenceAPI.set(GIC_PERSISTENT_STATE_PREFERENCE_KEY, view);
				update((state) => ({
					...state,
					view,
				}));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'persistentIssueCreateModalViewState',
						packageName: 'jiraRouterResourcesPersistentIssueCreateModalView',
						teamName: 'gryffindor',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		},
		[update],
	);

	const updateIsDiscoPushComplete = useCallback(
		async (isDiscoPushComplete = true) => {
			try {
				await userPreferenceAPI.set(
					GIC_MINI_MODAL_DISCOVERABILITY_PREFERENCE_KEY,
					isDiscoPushComplete,
				);
				update((state) => ({
					...state,
					isDiscoPushComplete,
				}));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'miniModalDiscoverabilityPushState',
						packageName: 'jiraRouterResourcesPersistentIssueCreateModalView',
						teamName: 'gryffindor',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		},
		[update],
	);

	return {
		data,
		updateView,
		updateIsDiscoPushComplete,
	};
};
