import React, { forwardRef, useState } from 'react';
import { Notifications as AkNotification } from '@atlaskit/atlassian-navigation';
import {
	SkeletonNotificationButton,
	Nav4SkeletonNotificationButton,
} from '@atlaskit/atlassian-navigation/skeleton-notification-button';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import {
	useUsingNotificationsToImproveAdminApprovalContext,
	UsingNotificationsToImproveAdminApprovalSpotlight,
	CoordinationStopProvider,
	SPOTLIGHT_MESSAGE_ID,
} from '@atlassian/jira-cross-flow-using-notifications-to-improve-admin-approval';
import CoordinationClient from '@atlassian/jira-engagement';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { Notifications as Nav4Notifications } from '@atlassian/jira-navigation-system';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import type { ExperimentalNotificationProps } from '../../types';

// Should this live in the migration layer (@atlassian/jira-navigation-system) instead?
const renderNotifications = (
	props: ExperimentalNotificationProps,
	ref: React.ForwardedRef<HTMLElement>,
) => {
	const { onViewRequests, ...notificationProps } = props;
	if (getWillShowNav4()) {
		const { badge, onClick, isSelected, label } = props;
		return (
			<Nav4Notifications
				badge={badge}
				label={label}
				onClick={onClick}
				isSelected={isSelected}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- PLEASE FIX - ENABLING FLAT LINT CONFIG
				ref={ref as React.ForwardedRef<HTMLButtonElement>}
			/>
		);
	}
	const buttonProps = {
		...notificationProps,
		ref,
	};

	return <AkNotification {...buttonProps} />;
};

export const Notification = forwardRef<HTMLElement, ExperimentalNotificationProps>((props, ref) => {
	const { markSpotlightAsShown, hasSpotlightBeenShown } =
		useUsingNotificationsToImproveAdminApprovalContext();

	const [isSpotlightActive, setIsSpotlightActive] = useState(true);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useTenantContext();

	if (__SERVER__) {
		if (getWillShowNav4()) {
			// @ts-expect-error - Type 'ReactNode' is not assignable to type 'string'.
			return <Nav4SkeletonNotificationButton label={props.tooltip} />;
		}

		// @ts-expect-error - Type 'ReactNode' is not assignable to type 'string'.
		return <SkeletonNotificationButton label={props.tooltip} />;
	}

	const { onViewRequests, ...notificationProps } = props;

	/**
	 * Send analytics event when user clicks "Close" or "View requests" button on the request spotlight
	 * Event registry - https://data-portal.internal.atlassian.com/analytics/registry/52348
	 * @param {"close"|"viewRequests"} buttonType
	 */
	const handleSendAnalyticsEventForClickRequestSpotlightButton = (
		buttonType: 'close' | 'viewRequests',
	) => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});
		fireUIAnalytics(analyticsEvent, 'requestSpotlightButton', {
			buttonType,
			isSiteAdmin,
		});
	};

	const shouldShowRequestsSpotlight = isSiteAdmin && !hasSpotlightBeenShown;

	if (!shouldShowRequestsSpotlight) {
		return renderNotifications(notificationProps, ref);
	}

	return (
		<SpotlightManager>
			<SpotlightTarget name="admin-request-spotlight">
				{renderNotifications(notificationProps, ref)}
			</SpotlightTarget>
			<SpotlightTransition>
				{isSpotlightActive && (
					<CoordinationClient messageId={SPOTLIGHT_MESSAGE_ID} messageType="engagement">
						<CoordinationStopProvider>
							{(stop) => (
								<UsingNotificationsToImproveAdminApprovalSpotlight
									onViewRequests={() => {
										setIsSpotlightActive(false);
										markSpotlightAsShown();
										onViewRequests && onViewRequests();
										handleSendAnalyticsEventForClickRequestSpotlightButton('viewRequests');
										stop && stop();
									}}
									onClose={() => {
										setIsSpotlightActive(false);
										markSpotlightAsShown();
										handleSendAnalyticsEventForClickRequestSpotlightButton('close');
										stop && stop();
									}}
								/>
							)}
						</CoordinationStopProvider>
					</CoordinationClient>
				)}
			</SpotlightTransition>
		</SpotlightManager>
	);
});
