import { useCallback, useEffect, useState } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch';
import { useSpaStatePageReady } from '@atlassian/jira-spa-state-controller';

const deferredPromise = () => {
	const deferred = {
		resolve: undefined,
		reject: undefined,
	};

	const promise: Promise<boolean> = new Promise(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(resolve: (result: Promise<boolean> | boolean) => void, reject: (error?: any) => void) => {
			// @ts-expect-error - TS2322 - Type '(result: boolean) => void' is not assignable to type 'undefined'.
			deferred.resolve = (result: boolean) => {
				resolve(result);
			};
			// @ts-expect-error - TS2322 - Type '(error?: any) => void' is not assignable to type 'undefined'.
			deferred.reject = reject;
		},
	);

	return {
		promise,
		...deferred,
	};
};

class BrowseUsersGroupsCacheClient {
	promise: Promise<boolean> | null = null;

	fetch() {
		const requestUrl = '/rest/internal/latest/userPermission/browseUsersGroups';

		if (this.promise !== null) {
			return this.promise;
		}

		this.promise = performGetRequest(requestUrl);

		return this.promise;
	}
}

const client = new BrowseUsersGroupsCacheClient();

export const useBrowseUsersGroupsPermissionsNext = (force?: boolean) => {
	const [{ isReady }] = useSpaStatePageReady();
	const [{ promise, resolve, reject }] = useState(deferredPromise());

	useEffect(() => {
		if ((isReady || force) && fg('dont-auto-close-people-menu')) {
			client.fetch().then(resolve).catch(reject);
		}
	}, [isReady, reject, resolve, force]);

	return useCallback(() => promise, [promise]);
};

export const useBrowseUsersGroupsPermissions = () => {
	const [{ isReady }] = useSpaStatePageReady();
	const [{ promise, resolve, reject }] = useState(deferredPromise());

	useEffect(() => {
		if (isReady && !fg('dont-auto-close-people-menu')) {
			const requestUrl = '/rest/internal/latest/userPermission/browseUsersGroups';

			performGetRequest(requestUrl).then(resolve).catch(reject);
		}
	}, [isReady, reject, resolve]);

	return useCallback(() => promise, [promise]);
};
