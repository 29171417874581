import React, { type ReactNode, useCallback } from 'react';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer';
import ErrorBoundary, {
	type ExtraErrorAttributes,
} from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';

export type ModalBoundaryProps = {
	children: ReactNode;
	id: string;
	metricKey?: string;
	packageName: string;
	onError?: (location: string, error: Error, attributes: ExtraErrorAttributes) => void;
};

export const ModalBoundary = ({
	children,
	id,
	metricKey,
	packageName,
	onError,
}: ModalBoundaryProps) => {
	const ErrorState = useCallback(
		(args: { error: Error }) => <ErrorFlagRenderer id={id} error={args.error} />,
		[id],
	);
	const fallback =
		typeof metricKey === 'string' && metricKey.length > 0 ? (
			<PerformanceMark metricKey={metricKey} />
		) : (
			<></>
		);

	return (
		<ErrorBoundary id={id} packageName={packageName} render={ErrorState} onError={onError}>
			<Placeholder name={id} fallback={fallback}>
				{children}
			</Placeholder>
		</ErrorBoundary>
	);
};
