import uiWrapperEditionAwarenessDropdownWrapperQuery$variables from '@atlassian/jira-relay/src/__generated__/uiWrapperEditionAwarenessDropdownWrapperQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointParams } from './types';

export const editionAwarenessDropdownEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "edition-awareness-dropdown" */ './src/ui/index'),
	),
	getPreloadProps: ({ cloudId, productKey }: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			editionAwareness: {
				parameters: uiWrapperEditionAwarenessDropdownWrapperQuery$variables,
				variables: {
					cloudId,
					hamsProductKey: productKey,
				},
			},
		},
	}),
});
