import {
	BOARD_TYPE,
	FILTER_TYPE,
	DASHBOARD_TYPE,
	PROJECT_TYPE,
	QUEUE_TYPE,
	PLAN_TYPE,
} from '@atlassian/jira-nav-item-service/src/constants';
import { fetchFavoriteItems } from '@atlassian/jira-nav-item-service/src/services/favorite-item/index.tsx';
import type { FavouriteResponseItem } from '@atlassian/jira-nav-item-service/src/types';
import {
	createStore,
	createContainer,
	createHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { FAVORITE_STORE_NAME, DEFAULT_LOAD_COUNT_FAV } from './constants';
import type { State, ContainerProps, ItemType } from './types';

export { DEFAULT_LOAD_COUNT_FAV };

const emptyItemState = {
	items: null,
	isFetching: false,
	fetchError: null,
	promise: null,
	hasFetchedOnce: false,
	hasSuccessOnce: false,
} as const;
export const initialState: State = {
	[BOARD_TYPE]: { ...emptyItemState },
	[DASHBOARD_TYPE]: { ...emptyItemState },
	[FILTER_TYPE]: { ...emptyItemState },
	[PROJECT_TYPE]: { ...emptyItemState },
	[QUEUE_TYPE]: { ...emptyItemState },
	[PLAN_TYPE]: { ...emptyItemState },
};

const transformItems = (items: FavouriteResponseItem[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items.reduce<Record<string, any>>((acc, itemResponse) => {
		if (itemResponse.id === 'favourite-boards') {
			acc[BOARD_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'favourite-filters') {
			acc[FILTER_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'favourite-dashboards') {
			acc[DASHBOARD_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'favourite-projects') {
			acc[PROJECT_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'favourite-queues') {
			acc[QUEUE_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'favourite-plans') {
			acc[PLAN_TYPE] = itemResponse.items;
		}
		return acc;
	}, {});

const loadFavoriteItems =
	(amount: number = DEFAULT_LOAD_COUNT_FAV, types: ItemType[]) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const typesToFetch = types.filter((type) => !getState()[type].isFetching);

		if (typesToFetch.length) {
			try {
				const promise = fetchFavoriteItems({
					count: amount,
					types: typesToFetch,
				});

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const loadingState = typesToFetch.reduce<Record<string, any>>((acc, type) => {
					acc[type] = {
						...getState()[type],
						isFetching: true,
						fetchError: null,
						promise,
					};
					return acc;
				}, {});

				setState(loadingState);

				const itemResponse = await promise;
				const transformed = transformItems(itemResponse);

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const responseState = typesToFetch.reduce<Record<string, any>>((acc, type) => {
					acc[type] = {
						items: transformed[type],
						isFetching: false,
						promise: null,
						fetchError: null,
						hasFetchedOnce: true,
						hasSuccessOnce: true,
					};
					return acc;
				}, {});

				setState(responseState);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (fetchError: any) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const errorState = typesToFetch.reduce<Record<string, any>>((acc, type) => {
					acc[type] = {
						...getState()[type],
						isFetching: false,
						promise: null,
						fetchError,
						hasFetchedOnce: true,
					};
					return acc;
				}, {});

				setState(errorState);
			}
		}
	};

export const actions = {
	loadFavoriteAll:
		(amount?: number) => async (actions2: StoreActionApi<State>, props: ContainerProps) => {
			loadFavoriteItems(
				amount,
				[
					props.applicationPermissions.hasSoftwareAccess ? BOARD_TYPE : undefined,
					DASHBOARD_TYPE,
					PROJECT_TYPE,
					FILTER_TYPE,
					props.applicationPermissions.hasServiceDeskAccess ? QUEUE_TYPE : undefined,
					props.applicationPermissions.hasSoftwareAccess ? PLAN_TYPE : undefined,
				].filter(Boolean),
			)(actions2);
		},
	loadFavouriteBoards: (amount?: number) => loadFavoriteItems(amount, [BOARD_TYPE]),
	loadFavoriteProjects: (amount?: number) => loadFavoriteItems(amount, [PROJECT_TYPE]),
	loadFavoriteDashboards: (amount?: number) => loadFavoriteItems(amount, [DASHBOARD_TYPE]),
	loadFavoriteFilters: (amount?: number) => loadFavoriteItems(amount, [FILTER_TYPE]),
	loadFavouriteQueues: (amount?: number) => loadFavoriteItems(amount, [QUEUE_TYPE]),
	loadFavoritePlans: (amount?: number) => loadFavoriteItems(amount, [PLAN_TYPE]),
} as const;

const store = createStore({
	name: FAVORITE_STORE_NAME,
	actions,
	initialState,
});

export type Actions = typeof actions;

export const useFavoriteActions = createHook(store, {
	selector: null,
});

export const useFavoriteBoards = createHook(store, {
	selector: (state) => state[BOARD_TYPE],
});

export const useFavoriteDashboards = createHook(store, {
	selector: (state) => state[DASHBOARD_TYPE],
});

export const useFavoriteProjects = createHook(store, {
	selector: (state) => state[PROJECT_TYPE],
});

export const useFavoriteFilters = createHook(store, {
	selector: (state) => state[FILTER_TYPE],
});

export const useFavoriteQueues = createHook(store, {
	selector: (state) => state[QUEUE_TYPE],
});

export const useFavoritePlans = createHook(store, {
	selector: (state) => state[PLAN_TYPE],
});

export const FavoritesContainer = createContainer<State, Actions, ContainerProps>(store);
