import React, { useCallback, useMemo, type ReactNode, type SyntheticEvent } from 'react';
import Popup from '@atlaskit/popup';
import { EditionAwarenessButton } from '@atlassian/growth-pattern-library-edition-awareness-button';
import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types';
import { editionAwarenessDropdownEntryPoint } from '@atlassian/jira-edition-awareness-dropdown/entrypoint';
import type { SupportedApplicationEdition } from '@atlassian/jira-edition-awareness-dropdown/src/common/types';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { mergeRefs } from '@atlassian/jira-merge-refs';
import { useBoolean } from '@atlassian/jira-platform-react-hooks-use-boolean';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	ContextualAnalyticsData,
	FireScreenAnalytics,
	useAnalyticsEvents,
	DROPDOWN,
} from '@atlassian/jira-product-analytics-bridge';
import { useFetchOnboardingReverseTrial } from '@atlassian/jira-reverse-trial-utils/src/controllers/use-fetch-onboarding-reverse-trial';
import { useForceOpenPremiumPopupOnDay3 } from '@atlassian/jira-reverse-trial/src/controllers/use-force-open-premium-popup-on-day-3';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import type { AnalyticsAttributes } from '../trigger-button/types';

const fireExposure = () => {
	const [_, fire] = UNSAFE_noExposureExp('demonstrating_the_value_of_jira_premium_features');
	fire();
};

/**
 * This was a capability developed during reverse trials where we automatically open the dialog after
 * 3 days of being on the trial. This happens for all customers that have a premium trial on sign up.
 */
const useAutoOpenPopover = (openDialog: () => void, analyticsAttributes?: AnalyticsAttributes) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onPopupToggle = useCallback(() => {
		// this is just used when we are opening the dialog 3 days after the customer
		// has started their trial, to force it open without the customers interaction
		openDialog();
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'popup' }),
			'editionAwarenessTrialPillPopup',
			{
				...analyticsAttributes,
				flagKey: 'demonstrating_the_value_of_jira_premium_features',
				autoOpen: true,
				premiumFeaturesCohort: 'variant',
			},
		);
		fireExposure();
	}, [openDialog, createAnalyticsEvent, analyticsAttributes]);

	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();

	useForceOpenPremiumPopupOnDay3({
		shouldRun: Boolean(isReady && premiumTrialOnSignup),
		onPopupToggle,
	});
};

type DropdownProps = {
	productKey: CanonicalId;
	applicationEdition: SupportedApplicationEdition;
	children: ReactNode;
	status: 'default' | 'warning' | 'danger';
	analyticsAttributes?: AnalyticsAttributes;
};

export const Dropdown = ({
	productKey,
	applicationEdition,
	children,
	status,
	analyticsAttributes,
}: DropdownProps) => {
	const cloudId = useCloudId();

	const [dialogOpen, { on: openDialog, toggle: toggleDialog, off: closeDialog }] =
		useBoolean(false);

	useAutoOpenPopover(openDialog, analyticsAttributes);

	const handleTogglePopup = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'toggled popup', 'editionAwarenessTrialPillPopup', {
				...analyticsAttributes,
				show: !dialogOpen,
				flagKey: 'demonstrating_the_value_of_jira_premium_features',
				premiumFeaturesCohort: 'variant',
			});
			toggleDialog();
			fireExposure();
		},
		[analyticsAttributes, dialogOpen, toggleDialog],
	);

	const entryPointParams = useMemo(() => ({ cloudId, productKey }), [cloudId, productKey]);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		editionAwarenessDropdownEntryPoint,
		entryPointParams,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions, true);

	const runtimeProps = useMemo(
		() => ({ productKey, applicationEdition }),
		[productKey, applicationEdition],
	);

	return (
		<ContextualAnalyticsData sourceName="editionAwareness" sourceType={DROPDOWN}>
			<Popup
				isOpen={dialogOpen}
				onClose={closeDialog}
				placement="bottom-start"
				content={() => (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="editionAwarenessDropdown"
						packageName="@atlassian/jira-navigation-apps-sidebar-edition-awareness"
						teamName="Flywheel-Tako"
						runtimeProps={runtimeProps}
						fallback={null}
					/>
				)}
				trigger={(triggerProps) => (
					<EditionAwarenessButton
						{...triggerProps}
						onClick={handleTogglePopup}
						ref={mergeRefs(triggerProps.ref, triggerRef)}
						status={status}
					>
						{children}
					</EditionAwarenessButton>
				)}
			/>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
