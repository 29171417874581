import type { Action } from '@atlassian/react-sweet-state';
import { cachedEndpointCall } from '../../services/cached-endpoint';
import {
	trackPersonalisation,
	fetchOnboardingPersonalisationWithRethrow,
} from '../../services/onboarding-personalisation';
import type { FetchOnboardingPersonalisationArgs } from '../../services/onboarding-personalisation/types';
import type { OnboardingPersonalisationState } from './types';

const fetchOnboardingPersonalisation = cachedEndpointCall(
	(args: FetchOnboardingPersonalisationArgs) => fetchOnboardingPersonalisationWithRethrow(args),
);

const trackPersonalisationAction =
	({
		additionalParameters,
	}: FetchOnboardingPersonalisationArgs): Action<OnboardingPersonalisationState> =>
	() =>
		trackPersonalisation({ additionalParameters });

export const actions = {
	fetchOnboardingPersonalisation,
	trackPersonalisation: trackPersonalisationAction,
};
