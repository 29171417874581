import { useState, useMemo } from 'react';
import type { Handlers } from './types';

/**
 * A react hook that handles boolean state with useful utility functions.
 *
 * @example
 * ```
 * function Example() {
 *   const [isHovering, setIsHovering] = useBoolean(false);
 *
 *   return (
 *     <div onMouseEnter={setIsHovering.on} onMouseLeave={setIsHovering.off}>
 *       {isHovering ? 'Is hovering' : 'Is not hovering'}
 *     </div>
 *   )
 * }
 * ```
 *
 * @example
 * ```
 * function Example() {
 *   const [isOpen, { off: handleClose, toggle }] = useBoolean(false);
 *
 *   return (
 *     <Popup
 *       isOpen={isOpen}
 *       onClose={handleClose}
 *       placement="bottom-start"
 *       content={() => (<p>Hello world!</p>)}
 *       trigger={(triggerProps) => (
 *         <Button
 *           {...triggerProps}
 *           appearance="primary"
 *           onClick={toggle}
 *         >
 *            Toggle
 *         </Button>
 *       )}
 *     />
 *   )
 * }
 * ```
 *
 * @param initialValue - Initial state. Set to `false` if no value is passed.
 * @returns A stateful value and utility functions to update the state.
 */
export function useBoolean(initialValue = false): [boolean, Handlers] {
	if (typeof initialValue !== 'boolean') {
		throw new Error('initialValue must be `true` or `false`');
	}
	const [value, setValue] = useState(initialValue);

	const handlers = useMemo<Handlers>(
		() => ({
			toggle: () => setValue((prevValue) => !prevValue),
			on: () => setValue(true),
			off: () => setValue(false),
			setValue,
		}),
		[],
	);

	return [value, handlers];
}
