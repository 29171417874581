import React from 'react';
// eslint-disable-next-line jira/import/no-restricted-import
import { Spotlight } from '@atlaskit/onboarding';
import {
	type MaybeChoreographedComponentProps,
	shouldBeChoreographed,
} from '@atlassian/jira-choreographer-services';
import { fg } from '@atlassian/jira-feature-gating';
import { ChoreographerSpotlight } from './choreographer-spotlight';
import type { SpotlightProps } from './types';

export type JiraSpotlightProps = SpotlightProps & MaybeChoreographedComponentProps;

export const JiraSpotlight = (props: JiraSpotlightProps) => {
	if (shouldBeChoreographed(props) && fg('spotlight_conversion_to_choreographer_spotlight')) {
		const { messageType, ...rest } = props;
		return <ChoreographerSpotlight {...rest} />;
	}

	const { messageType, messageId, onMessageDisposition, ...rest } = props;
	return <Spotlight {...rest} />;
};
