import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

export const useIsTargetedForReverseTrials = (): boolean => {
	const { data: projectContextData } = useProjectContext();

	const jswEdition = useAppEditions().software;

	if (fg('jsw_reverse_trials_feature_gate')) {
		const isCurrentProjectJSW = projectContextData?.projectType === SOFTWARE_PROJECT;

		if (jswEdition === PREMIUM_EDITION && isCurrentProjectJSW) {
			return true;
		}

		return false;
	}
	return false;
};
