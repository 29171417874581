import fireErrorAnalytics, {
	type ErrorPayload,
	trackFetchErrors,
	type AnalyticsPayload,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { JsdFetchError } from '../fetch/requests';

export const ANALYTIC_CONTEXTS = {
	AUTOMATION_SETTINGS: 'AutomationSettings',
	KNOWLEDGE: 'Knowledge',
	QUEUES: 'Queues',
	CSAT: 'Csat',
} as const;

export const ttrRecommendedSlo = 1000;
export const ttiRecommendedSlo = 4000;

export const trackJsdFetchErrors = async (payload: ErrorPayload) => {
	const { error, attributes } = payload;
	if (error instanceof JsdFetchError) {
		const { reasonKey } = error;
		trackFetchErrors({
			...payload,
			attributes: { ...attributes, reasonKey },
		});
		return;
	}
	trackFetchErrors(payload);
};

export function fireJsonOnlyErrorAnalytics({
	error,
	...rest
}: Omit<AnalyticsPayload, 'error'> & { error: unknown }) {
	let useError = false;
	if (error instanceof Error) {
		try {
			JSON.parse(error.message);
			useError = true;
		} catch (e) {
			useError = false;
		}
	}
	fireErrorAnalytics({
		error: error instanceof Error && useError ? error : undefined,
		...rest,
	});
}
