// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md

import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcImage from '../../assets/premium-trial.svg';

const srcLight = srcImage;
const srcDark = srcImage;

const Illustration = createIllustration({
	srcLight,
	srcDark,
	autoDark: false,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 241, 107];
export { srcLight, srcDark, viewBox };
