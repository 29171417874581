import type { Alert } from '@atlassian/eoc-alert-common';
import {
	createStore,
	createHook,
	type StoreActionApi,
	createContainer,
} from '@atlassian/react-sweet-state';
import { ALERTS_STORE_NAME } from './constants';
import { fetchAlerts } from './services';
import type {
	State,
	BaseState,
	ContainerProps,
	LoadAlertsParams,
	RemoveAlertParams,
} from './types';

export const baseState: BaseState = {
	items: null,
	isFetching: false,
	fetchError: null,
	promise: null,
	hasFetchedOnce: false,
	hasSuccessOnce: false,
	totalCount: 0,
};

export const initialState: State = {};

const loadAlerts =
	({ searchParams, key, shouldOverwrite = true }: LoadAlertsParams) =>
	async ({ setState, getState }: StoreActionApi<State>, { cloudId }: ContainerProps) => {
		if (!cloudId) {
			throw new Error(
				'`cloudId` is required please wrap your component with `AlertsStoreContainer`, and provide the prop.',
			);
		}

		if (!key) {
			throw new Error('`key` is required please provie a unique key for each query.');
		}

		try {
			const promise = fetchAlerts({ cloudId, searchParams });

			setState({
				...getState(),
				[key]: {
					...baseState,
					...getState()[key],
					isFetching: true,
					fetchError: null,
					promise,
				},
			});

			const response = await promise;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const alerts = (response?.values as unknown as Alert[]) ?? null;

			const newItems = shouldOverwrite ? alerts : [...(getState()[key]?.items ?? []), ...alerts];

			setState({
				...getState(),
				[key]: {
					...getState()[key],
					items: newItems,
					totalCount: newItems.length,
					hasSuccessOnce: true,
					hasFetchedOnce: true,
					isFetching: false,
				},
			});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...getState(),
				[key]: {
					...baseState,
					...getState()[key],
					fetchError: error,
					hasFetchedOnce: true,
					isFetching: false,
				},
			});
			throw error;
		}
	};

const removeAlert =
	({ alertId, key }: RemoveAlertParams) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			[key]: {
				...baseState,
				...getState()[key],
				items: getState()[key]?.items?.filter((alert) => alert.id !== alertId) ?? [],
			},
		});
	};

export const actions = { loadAlerts, removeAlert } as const;

type Actions = typeof actions;

const alertsStore = createStore({ name: ALERTS_STORE_NAME, actions, initialState });

export const useAlertsStore = createHook(alertsStore, { selector: (state) => state });

export const AlertsStoreContainer = createContainer<State, Actions, ContainerProps>(alertsStore);
