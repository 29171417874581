import React, { useMemo } from 'react';
import Avatar from '@atlaskit/avatar';
import { HeadingItem } from '@atlaskit/menu';
import { PROJECTS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider';
import { useIntl } from '@atlassian/jira-intl';
import {
	getBeforeEntity,
	testIdConcat,
	getLinkFromProject,
} from '@atlassian/jira-navigation-apps-common';
import { DEFAULT_LOAD_COUNT_RECENT } from '../../../../common/constants';
import { FavoriteButton } from '../../../../common/ui/menu/favourite-button';
import { MenuItem } from '../../../../common/ui/menu/item';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list';
import { useProjectNavItems as useItems } from '../../../../controllers/items';
import messages from './messages';
import type { RecentContentProps } from './types';

export const RecentContent = ({ testIdPrefix }: RecentContentProps) => {
	const { formatMessage } = useIntl();
	const { recentItems, favoriteItems, changeFavorite: onChangeFavorite, changedItems } = useItems();
	const itemsToShow = useMemo(() => recentItems.slice(0, DEFAULT_LOAD_COUNT_RECENT), [recentItems]);

	const testId = testIdConcat(testIdPrefix, 'items-recent');

	return recentItems.length > 0 ? (
		<span data-testid={testId}>
			<HeadingItem testId={testIdConcat(testId, 'heading')}>
				{formatMessage(messages.recent)}
			</HeadingItem>
			<MenuList>
				{itemsToShow.map((item, idx) => {
					const { url, id, favourite: isFavourite, metadata, title } = item;
					const contextItem = changedItems[item.id.toString()];
					const isPending =
						contextItem && contextItem.pending === true ? contextItem.pending : false;
					const { id: beforeEntityId, type: beforeEntityType } = getBeforeEntity(favoriteItems);

					const { params, query, route: to } = getLinkFromProject(item);

					return (
						<MenuListItem key={id} ariaLabel={formatMessage(messages.recent)}>
							<MenuItem
								analytics={{
									actionSubjectId: 'projectsNavigationMenuItem',
									menuItemId: id,
									menuItemType: 'recent',
									position: idx,
									starred: Boolean(isFavourite),
								}}
								description={metadata}
								href={url}
								iconBefore={
									<Avatar
										appearance="square"
										size="small"
										borderColor="transparent"
										src={item.avatarUrl}
									/>
								}
								iconAfter={
									<FavoriteButton
										beforeEntityId={beforeEntityId}
										beforeEntityType={beforeEntityType}
										id={String(id)}
										isFavorite={Boolean(isFavourite)}
										favoriteItemName={title}
										isPending={isPending}
										menuItemType="recent"
										onChangeFavorite={onChangeFavorite}
										type={PROJECTS_ITEM_TYPE}
									/>
								}
								params={params}
								query={query}
								testId={testIdConcat(testId, `item-${idx}`)}
								to={to}
							>
								{title}
							</MenuItem>
						</MenuListItem>
					);
				})}
			</MenuList>
		</span>
	) : null;
};
