import React from 'react';
import { Section, HeadingItem } from '@atlaskit/menu';
import {
	FeedbackActionsSubscriber,
	type FeedbackCollectorProps,
	ENTRYPOINT_ID_JIRA_PLATFORM,
} from '@atlassian/jira-feedback-collector';
import type { State } from '@atlassian/jira-feedback-collector/src/controllers/types';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useContainerContext } from '@atlassian/jira-providers-container-context';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';
import {
	useProductName,
	useIsAdmin,
	useIsAnonymous,
	useIsSiteAdmin,
} from '@atlassian/jira-tenant-context-controller';
import type { Action } from '@atlassian/react-sweet-state';
import { MODAL_ID } from '../../../common/constants';
import { MenuItem } from '../../../common/ui/menu/item';
import { MenuLayout as Layout } from '../../../common/ui/menu/layout';
import messages from './messages';
import type { MenuProps } from './types';

const PLATFORM_FEEDBACK_GROUP_ID = 'jira-platform-general';
const unknownAttributeValue = 'unknown';
const docsUrlMap = {
	software: 'https://confluence.atlassian.com/jirasoftwarecloud/',
	serviceDesk: 'https://confluence.atlassian.com/servicedeskcloud/',
	core: 'https://confluence.atlassian.com/jiracorecloud/',
	jira: 'https://confluence.atlassian.com/alldoc/',
	// POL-1811 JPD temporary docs url, to be replaced once available
	'product-discovery': 'https://confluence.atlassian.com/alldoc/',
} as const;
const GET_JIRA_MOBILE_APP_PAGE =
	'https://www.atlassian.com/software/jira/mobile-app?source=jira-help';

export const Menu = ({ testIdPrefix }: MenuProps) => {
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const isAnonymous = useIsAnonymous();
	const isSiteAdmin = useIsSiteAdmin();
	const productName = useProductName();

	const [, { on: openKeyboardShortcuts }] = useSwitchModals(MODAL_ID.KEYBOARD_SHORTCUTS);
	let position = 0;

	const testId = testIdConcat(testIdPrefix, 'menu-popup');

	const route = useCurrentRoute();
	const [{ data: projectData }] = useContainerContext();

	const routeGroup = route.group ?? unknownAttributeValue;
	const routeName = route.name ?? unknownAttributeValue;

	let projectType = unknownAttributeValue;
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	if (projectData && projectData.project) {
		// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
		projectType = projectData.project.type;
	}

	return (
		<span data-testid={testId}>
			<Layout>
				<Section>
					<HeadingItem testId={testIdConcat(testId, 'help', 'heading')}>
						{formatMessage(messages.headingHelp)}
					</HeadingItem>
					<MenuItem
						analytics={{
							actionSubjectId: 'helpNavigationMenuItem',
							menuItemId: 'atlassian-documentation',
							menuItemType: 'help',
							position: position++,
						}}
						href={docsUrlMap[productName]}
						target="_blank"
						testId={testIdConcat(testId, 'help', 'item-atlassian-documentation')}
					>
						{formatMessage(messages.atlassianDocumentation)}
					</MenuItem>
					<MenuItem
						analytics={{
							actionSubjectId: 'helpNavigationMenuItem',
							menuItemId: 'atlassian-community',
							menuItemType: 'help',
							position: position++,
						}}
						href="https://community.atlassian.com"
						target="_blank"
						testId={testIdConcat(testId, 'help', 'item-atlassian-community')}
					>
						{formatMessage(messages.atlassianCommunity)}
					</MenuItem>
					<MenuItem
						analytics={{
							actionSubjectId: 'helpNavigationMenuItem',
							menuItemId: 'whats-new',
							menuItemType: 'help',
							position: position++,
						}}
						href="https://confluence.atlassian.com/pages/viewrecentblogposts.action?key=Cloud"
						target="_blank"
						testId={testIdConcat(testId, 'help', 'item-whats-new')}
					>
						{formatMessage(messages.whatsNew)}
					</MenuItem>
					{isAdmin || isSiteAdmin ? (
						<MenuItem
							analytics={{
								actionSubjectId: 'helpNavigationMenuItem',
								menuItemId: 'raise-a-bug',
								menuItemType: 'help',
								position: position++,
							}}
							href="https://support.atlassian.com/contact/"
							target="_blank"
							testId={testIdConcat(testId, 'help', 'item-raise-a-bug')}
						>
							{formatMessage(messages.raiseABug)}
						</MenuItem>
					) : null}
					{/* @ts-expect-error - TS2745 - This JSX tag's 'children' prop expects type 'never' which requires multiple children, but only a single child was provided. */}
					<FeedbackActionsSubscriber>
						{(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							__: any,
							{
								openFeedbackCollector,
							}: {
								openFeedbackCollector: (
									feedbackCollectorProps: FeedbackCollectorProps,
								) => Action<State, void, void | Promise<void>>;
							},
						) => (
							<MenuItem
								analytics={{
									actionSubjectId: 'helpNavigationMenuItem',
									menuItemId: 'feedback',
									menuItemType: 'help',
									position: position++,
								}}
								onClick={() => {
									openFeedbackCollector({
										entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
										feedbackGroupId: PLATFORM_FEEDBACK_GROUP_ID,
										additionalFields: [
											{
												id: 'route-name',
												value: routeName,
											},
											{
												id: 'route-group',
												value: routeGroup,
											},
											{
												id: 'project-type',
												value: projectType,
											},
										],
									});
								}}
								testId={testIdConcat(testId, 'help', 'item-feedback')}
							>
								{formatMessage(messages.giveFeedback)}
							</MenuItem>
						)}
					</FeedbackActionsSubscriber>
					{!isAnonymous && (
						<MenuItem
							analytics={{
								actionSubjectId: 'helpNavigationMenuItem',
								menuItemId: 'get-mobile',
								menuItemType: 'help',
								position: position++,
							}}
							testId={testIdConcat(testId, 'help', 'item-get-mobile')}
							href={GET_JIRA_MOBILE_APP_PAGE}
							target="_blank"
						>
							{formatMessage(messages.getMobile)}
						</MenuItem>
					)}
					<MenuItem
						analytics={{
							actionSubjectId: 'helpNavigationMenuItem',
							menuItemId: 'keyboard-shortcuts',
							menuItemType: 'help',
							position: position++,
						}}
						onClick={openKeyboardShortcuts}
						testId={testIdConcat(testId, 'help', 'item-keyboard-shortcuts')}
					>
						{formatMessage(messages.keyboardShortcuts)}
					</MenuItem>
					<HeadingItem testId={testIdConcat(testId, 'legal', 'heading')}>
						{formatMessage(messages.headingLegal)}
					</HeadingItem>
					<MenuItem
						analytics={{
							actionSubjectId: 'helpNavigationMenuItem',
							menuItemId: 'terms-of-use',
							menuItemType: 'legal',
							position: 0,
						}}
						href="https://www.atlassian.com/legal/customer-agreement"
						target="_blank"
						testId={testIdConcat(testId, 'legal', 'item-terms-of-use')}
					>
						{formatMessage(messages.termsOfUse)}
					</MenuItem>
					<MenuItem
						analytics={{
							actionSubjectId: 'helpNavigationMenuItem',
							menuItemId: 'privacy-policy',
							menuItemType: 'legal',
							position: 1,
						}}
						href="https://www.atlassian.com/legal/privacy-policy"
						target="_blank"
						testId={testIdConcat(testId, 'legal', 'item-privacy-policy')}
					>
						{formatMessage(messages.privacyPolicy)}
					</MenuItem>
				</Section>
			</Layout>
		</span>
	);
};
