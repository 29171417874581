export const GENERIC_FORGE_USE_PRODUCT_MODALS = 'forge-open-issue-create' as const;
export const GENERIC_GLOBAL_CREATE_BUTTON = 'global-create-button' as const;
export const GENERIC_KEYBOARD_SHORTCUT = 'keyboard-shortcut' as const;
export const JSW_ISSUE_VIEW_CREATE_ISSUE_IN_EPIC = 'jsw-issue-view-create-issue-in-epic' as const;
export const JSW_ISSUE_VIEW_CREATE_SUBTASK = 'jsw-issue-view-create-subtask' as const;
export const JSW_ISSUE_VIEW_AI_BREAKDOWN_CHILD_ISSUE =
	'jsw-issue-view-ai-breakdown-child-issue' as const;
export const JSW_ISSUE_VIEW_AI_BREAKDOWN_SUBTASK = 'jsw-issue-view-ai-breakdown-subtask' as const;
export const JSW_RELEASES_CREATE_ISSUE = 'jsw-releases-create-issue' as const;
export const JSW_ROADMAP_CREATE_ISSUE = 'jsw-roadmap-create-issue' as const;
export const JSW_SECURITY_CREATE_ISSUE = 'jsw-security-create-issue' as const;
export const JSM_CALENDAR_CREATE_CHANGE_REQUEST = 'jsm-calendar-create-change-request' as const;
export const JSM_CREATE_INCIDENT_FROM_ALERT_DETAIL =
	'jsm-create-incident-from-alert-detail' as const;
export const JSM_CREATE_INCIDENT_FROM_ALERT_LIST = 'jsm-create-incident-from-alert-list' as const;
export const JSM_EMPTY_QUEUE_CREATE_REQUEST = 'jsm-empty-queue-create-request' as const;
export const JWM_BOARD_CREATE_ISSUE = 'jwm-board-create-issue' as const;
export const JWM_CALENDAR_CREATE_ISSUE = 'jwm-calendar-create-issue' as const;
export const JWM_LIST_CREATE_ISSUE = 'jwm-list-create-issue' as const;
export const JWM_LIST_CREATE_SUBTASK = 'jwm-list-create-subtask' as const;
export const JWM_TIMELINE_CREATE_ISSUE = 'jwm-timeline-create-issue' as const;
export const JWM_TIMELINE_CREATE_SUBTASK = 'jwm-timeline-create-subtask' as const;
export const JSW_CREATE_ISSUE_DIALOG = 'software-create-issue-dialog' as const;
export const ISSUE_VIEW_CREATE_LINKED_ISSUE = 'create-linked-issue-issue-view';
export const COMMAND_PALETTE_CREATE_LINKED_ISSUE = 'create-linked-issue-command-palette';
export const SERVICE_DESK_LINKED_ISSUE_TRIGEER = 'service-desk-linked-issue-trigger';
export const JSW_INCIDENTS_CREATE_JSM_PIR_TRIGGER = 'jsw-incidents-create-jsm-pir-trigger';
export const JSW_INCIDENTS_CREATE_ISSUE_TRIGGER = 'jsw-incidents-create-issue-trigger';
export const JPO_DIALOGS_CREATE_ISSUE_TRIGGER = 'jpo-dialogs-jira-create-issue' as const;
export const JSW_ADAPTED_GLOBAL_ISSUE_CREATE = 'jsw-adapted-global-issue-create-trigger' as const;
export const JSW_CREATE_EPIC_ISSUE = 'jsw-create-epic-issue' as const;
export const CREATE_ISSUE_BACKLOG_TRIGGER = 'create-issue-backlog-trigger' as const;
export const CREATE_EPIC_ISSUE_BACKLOG_TRIGGER = 'create-epic-issue-backlog-trigger' as const;
export const CREATE_ISSUE_IN_EPIC_BACKLOG_TRIGGER = 'create-issue-in-epic-backlog-trigger' as const;
export const CREATE_ISSUE_ACTIVE_SPRINT_EMPTY_VIEW =
	'create-issue-active-sprint-empty-view-trigger' as const;
export const CREATE_LINKED_ISSUE_NAVIGATOR = 'create-linked-issue-issue-navigator-trigger' as const;
export const JWM_SUMMARY_CREATE_FIRST_ISSUE_TRIGGER =
	'jwm-summary-create-first-issue-trigger' as const;
export const CREATE_SUBTASK_ISSUE_NAVIGATOR_TRIGGER =
	'create-subtask-issue-navigator-trigger' as const;
export const CREATE_SUBTASK_NIN_TRIGGER = 'create-subtask-new-issue-navigator-trigger' as const;
export const JWM_BOARD_EMBED_ISSUE_CREATE = 'jwm-board-embed-issue-create' as const;
export const TRIGGER_POINT_KEY_NOT_DEFINED = 'trigger-point-key-not-defined' as const;
export const JWM_LIST_EMBED_ISSUE_CREATE = 'jwm-list-embed-issue-create' as const;
export const ISSUE_PARENT_SWITCHER_FIELD_CREATE_ISSUE =
	'issue-parent-switcher-field-create-issue' as const;
export const POLARIS_CREATE_DELIVERY_ISSUE = 'polaris-create-delivery-issue' as const;
export const HOME_PAGE_DEFAULT_OPEN_GIC = 'home-page-default-open-gic' as const;
export const ISSUE_CREATE_EMBEDDABLE_TRIGGER = 'issue-create-embed-trigger' as const;
export const DEVELOPER_ESCALATIONS_ISSUE_CREATE_TRIGGER =
	'developer-escalations-issue-create-trigger' as const;
export const JWM_INLINE_CREATE = 'jwm-inline-create' as const;
export const POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER =
	'polaris-global-submit-idea-switch-project-trigger' as const;
export const JIRA_CALENDAR_CREATE_ISSUE = 'jira-calendar-create-issue';
export const JIRA_CONNECT_APP_ECOSYSTEM = 'connect-app-ecosystem';

export const ALLOWED_EXTERNAL_CONTROLS = {
	FIELD_IDS: ['description'],
	TRIGGER_POINTS: ['service-desk-gen-ai-pir'],
} as const;
