import React, { type FunctionComponent, useEffect, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { slackServiceFactory } from '@atlassian/integrations';
import type { FlagComponentProps } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	ContextualAnalyticsData,
	SCREEN,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { BothSplash } from '@atlassian/jira-third-party-nudge/src/ui/third-party-nudge-flag/splashes/both-splash/index.tsx';
import { NotificationBellBothSplash } from '@atlassian/jira-third-party-nudge/src/ui/third-party-nudge-flag/splashes/notification-bell-splashes/both-splash/index.tsx';
import { NotificationBellSlackSplash } from '@atlassian/jira-third-party-nudge/src/ui/third-party-nudge-flag/splashes/notification-bell-splashes/slack-splash/index.tsx';
import { NotificationBellTeamsSplash } from '@atlassian/jira-third-party-nudge/src/ui/third-party-nudge-flag/splashes/notification-bell-splashes/teams-splash/index.tsx';
import { SlackSplash } from '@atlassian/jira-third-party-nudge/src/ui/third-party-nudge-flag/splashes/slack-splash/index.tsx';
import { TeamsSplash } from '@atlassian/jira-third-party-nudge/src/ui/third-party-nudge-flag/splashes/teams-splash/index.tsx';
import { TeamsDeepLinkSource } from '../../common/constants';
import { setHasSeenNudge } from '../../services/local-storage';
import { setNotificationBellNudgeState } from '../../services/session-storage';
import type { ThirdPartyNudgeVariant } from '../../types';
import { openTeamsDeepLink } from '../../utils';
import { SlackConnectButton, MicrosoftTeamsConnectButton } from './connect-button';
import { messages } from './messages';
import { NudgeFlag } from './nudge-flag';

export interface ThirdPartyFlagProps {
	id: FlagComponentProps['id'];
	cloudId: string;
	variant: ThirdPartyNudgeVariant;
	onDismiss: () => void;
}

export const ThirdPartyIssueCommentFlag: FunctionComponent<ThirdPartyFlagProps> = ({
	cloudId,
	variant,
	onDismiss,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	useEffect(() => {
		setHasSeenNudge();
	}, []);

	const slackService = useMemo(
		() => slackServiceFactory(cloudId, 'jira', createAnalyticsEvent),
		[cloudId, createAnalyticsEvent],
	);

	const handleConnectSlackButtonClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'thirdPartyConnect', {
			thirdPartyApp: 'slack',
			trigger: 'after-issue-comment',
		});

		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		slackService.getConsent(() => {});
	}, [createAnalyticsEvent, slackService]);

	const handleConnectTeamsButtonClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'thirdPartyConnect', {
			thirdPartyApp: 'msteams',
			trigger: 'after-issue-comment',
		});

		openTeamsDeepLink();
	}, [createAnalyticsEvent]);

	const handleDismissButtonClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'thirdPartyNudgeJswComment', {
			suggestedThirdPartyApps: variant === 'both' ? ['slack', 'msteams'] : [variant],
			trigger: 'after-issue-comment',
		});
		onDismiss();
	}, [createAnalyticsEvent, onDismiss, variant]);

	return (
		<ContextualAnalyticsData
			sourceName="thirdPartyNudge"
			sourceType={SCREEN}
			attributes={{
				suggestedThirdPartyApps: variant === 'both' ? ['slack', 'msteams'] : [variant],
				trigger: 'after-issue-comment',
			}}
		>
			<FireScreenAnalytics />
			<NudgeFlag onDismiss={handleDismissButtonClick}>
				{variant === 'slack' && <SlackSplash />}
				{variant === 'msteams' && <TeamsSplash />}
				{variant === 'both' && <BothSplash />}
				<FlagBodyWrapper>
					<FlagBodyContentWrapper>
						<FlagHeader>{formatMessage(messages.title)}</FlagHeader>
						<FlagDescription>
							{variant === 'slack' && formatMessage(messages.descriptionSlack)}
							{variant === 'msteams' && formatMessage(messages.descriptionTeams)}
							{variant === 'both' && formatMessage(messages.descriptionBoth)}
						</FlagDescription>
					</FlagBodyContentWrapper>
					<CtaWrapper>
						{variant === 'slack' && (
							<Button
								appearance="primary"
								testId="third-party-nudge.ui.third-party-nudge-flag.connect-slack-button"
								onClick={handleConnectSlackButtonClick}
							>
								{formatMessage(messages.connectSlackButton)}
							</Button>
						)}
						{variant === 'msteams' && (
							<Button
								appearance="primary"
								testId="third-party-nudge.ui.third-party-nudge-flag.find-out-more-button"
								onClick={handleConnectTeamsButtonClick}
							>
								{formatMessage(messages.findOutMoreButton)}
							</Button>
						)}
						{variant === 'both' && (
							<ConnectIntegrationsWrapper>
								<ConnectIntegrationsHeader>
									{formatMessage(messages.connectHeader)}
								</ConnectIntegrationsHeader>
								<IntegrationButtonsWrapper>
									<SlackConnectButton onClick={handleConnectSlackButtonClick} />
									<MicrosoftTeamsConnectButton onClick={handleConnectTeamsButtonClick} />
								</IntegrationButtonsWrapper>
							</ConnectIntegrationsWrapper>
						)}
					</CtaWrapper>
				</FlagBodyWrapper>
			</NudgeFlag>
		</ContextualAnalyticsData>
	);
};

export const ThirdPartyNotificationBellFlag: FunctionComponent<ThirdPartyFlagProps> = ({
	cloudId,
	variant,
	onDismiss,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	useEffect(() => {
		setNotificationBellNudgeState();
	}, []);

	const slackService = useMemo(
		() => slackServiceFactory(cloudId, 'jira', createAnalyticsEvent),
		[cloudId, createAnalyticsEvent],
	);

	const handleConnectSlackButtonClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'notificationBellThirdPartyConnect',
			{
				thirdPartyApp: 'slack',
				trigger: 'notification-bell',
			},
		);

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		slackService.getConsent(() => {});
	}, [createAnalyticsEvent, slackService]);

	const handleConnectTeamsButtonClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'notificationBellThirdPartyConnect',
			{
				thirdPartyApp: 'msteams',
				trigger: 'notification-bell',
			},
		);

		openTeamsDeepLink(TeamsDeepLinkSource.NOTIFICATION_BELL);
	}, [createAnalyticsEvent]);

	const handleDismissButtonClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'notificationBellThirdPartyDismiss',
			{
				suggestedThirdPartyApps: variant === 'both' ? ['slack', 'msteams'] : [variant],
				trigger: 'notification-bell',
			},
		);
		onDismiss();
	}, [createAnalyticsEvent, onDismiss, variant]);

	return (
		<ContextualAnalyticsData
			sourceName="notificationBellThirdPartyNudge"
			sourceType={SCREEN}
			attributes={{
				suggestedThirdPartyApps: variant === 'both' ? ['slack', 'msteams'] : [variant],
				trigger: 'notification-bell',
			}}
		>
			<FireScreenAnalytics />
			<NudgeFlag onDismiss={handleDismissButtonClick}>
				{variant === 'slack' && <NotificationBellSlackSplash />}
				{variant === 'msteams' && <NotificationBellTeamsSplash />}
				{variant === 'both' && <NotificationBellBothSplash />}
				<FlagBodyWrapper>
					<FlagBodyContentWrapper>
						<FlagHeader>
							{variant === 'slack' && formatMessage(messages.notificationBellSlackTitle)}
							{variant === 'msteams' && formatMessage(messages.notificationBellTeamsTitle)}
							{variant === 'both' && formatMessage(messages.notificationBellBothTitle)}
						</FlagHeader>
						<FlagDescription>{formatMessage(messages.notificationBellDescription)}</FlagDescription>
					</FlagBodyContentWrapper>
					<CtaWrapper>
						{variant === 'slack' && (
							<Button
								appearance="primary"
								testId="third-party-nudge.ui.third-party-nudge-flag.slack-connect-button"
								onClick={handleConnectSlackButtonClick}
							>
								{formatMessage(messages.notificationBellConnectSlackButton)}
							</Button>
						)}
						{variant === 'msteams' && (
							<Button
								appearance="primary"
								testId="third-party-nudge.ui.third-party-nudge-flag.teams-connect-button"
								onClick={handleConnectTeamsButtonClick}
							>
								{formatMessage(messages.notificationBellConnectTeamsButton)}
							</Button>
						)}
						{variant === 'both' && (
							<ConnectIntegrationsWrapper>
								<ConnectIntegrationsHeader>
									{formatMessage(messages.notificationBellConnectHeader)}
								</ConnectIntegrationsHeader>
								<IntegrationButtonsWrapper>
									<SlackConnectButton onClick={handleConnectSlackButtonClick} />
									<MicrosoftTeamsConnectButton onClick={handleConnectTeamsButtonClick} />
								</IntegrationButtonsWrapper>
							</ConnectIntegrationsWrapper>
						)}
					</CtaWrapper>
				</FlagBodyWrapper>
			</NudgeFlag>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConnectIntegrationsWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlagBodyContentWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlagHeader = styled.h4({
	font: token('font.heading.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlagDescription = styled.p({
	font: token('font.body'),
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConnectIntegrationsHeader = styled.p({
	font: token('font.heading.xxsmall'),
	color: token('color.text.subtlest', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IntegrationButtonsWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlagBodyWrapper = styled.div({
	padding: token('space.300', '24px'),
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CtaWrapper = styled.div({
	display: 'block',
});
