/**
 * @generated SignedSource<<90c2c84ac4c2fad1c6a72ce13eca9e2a>>
 * @relayHash 97a1c758d8ea29a7156d353d94f3ed9f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6795572f839a06492cca85ebe2360890afada153c5d10177b20a4a977949ca9d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiWrapperEditionAwarenessDropdownWrapperQuery } from './uiWrapperEditionAwarenessDropdownWrapperQuery.graphql';

const node: PreloadableConcreteRequest<uiWrapperEditionAwarenessDropdownWrapperQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6795572f839a06492cca85ebe2360890afada153c5d10177b20a4a977949ca9d",
    "metadata": {},
    "name": "uiWrapperEditionAwarenessDropdownWrapperQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
