import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { CreateProjectPermissions, State } from '../types';

export const fetchCreateProjectPermission = async (): Promise<CreateProjectPermissions> => {
	const url = '/rest/internal/2/projectpermissions';
	const result = await fetchJson(url);
	return result;
};

const handleFetch =
	() =>
	// @ts-expect-error - TS7031 - Binding element 'setState' implicitly has an 'any' type. | TS7031 - Binding element 'getState' implicitly has an 'any' type.
	async ({ setState, getState }) => {
		const { isFetching } = getState();
		if (!isFetching) {
			try {
				const result = await fetchCreateProjectPermission();
				setState({
					...result,
					isFetching: false,
					fetchError: null,
					hasFetchedOnce: true,
					hasSuccessOnce: true,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({
					canCreateProject: false,
					canCreateClassicProject: false,
					canCreateNextGenProject: false,
					isFetching: false,
					fetchError: error,
					hasFetchedOnce: true,
				});
			}
		}
	};

export const actions = {
	fetchCreateProjectPermission:
		(): Action<State> =>
		async ({ setState, dispatch }) => {
			const promise = dispatch(handleFetch());

			setState({
				isFetching: true,
				// @ts-expect-error - TS2322 - Type 'Promise<void>' is not assignable to type 'Promise<undefined>'.
				promise,
			});
		},
} as const;
