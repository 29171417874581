import React, { useEffect, useRef } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { JWM_INVITE_TEAM_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { useHasNudgeActive, useNudgeStep } from '@atlassian/jira-onboarding-quickstart-core';
import { ERROR_BOUNDARY_ID_JWM_INVITE_TEAM_NUDGE } from '../../common/constants';
import { inviteTeamNudgeEntryPoint } from './entrypoint';

const runtimeProps = {};
const entryPointParams = {};
export const InviteTeamNudgeAsync = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		inviteTeamNudgeEntryPoint,
		entryPointParams,
	);

	const [nudgeStep] = useNudgeStep();
	const [hasNudgeActive] = useHasNudgeActive();
	const firstLoadRef = useRef(true);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (firstLoadRef.current && hasNudgeActive && nudgeStep === JWM_INVITE_TEAM_NUDGE_ID) {
			entryPointActions.load();
			firstLoadRef.current = false;
		}
	}, [entryPointActions, hasNudgeActive, nudgeStep]);

	return (
		<JiraEntryPointContainer
			id={ERROR_BOUNDARY_ID_JWM_INVITE_TEAM_NUDGE}
			packageName="jiraInviteTeamOnboarding"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={<></>}
			runtimeProps={runtimeProps}
		/>
	);
};
