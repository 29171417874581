import React, { forwardRef } from 'react';
import { Settings as AkSettings, type SettingsProps } from '@atlaskit/atlassian-navigation';
import {
	SkeletonSettingsButton,
	Nav4SkeletonSettingsButton,
} from '@atlaskit/atlassian-navigation/skeleton-settings-button';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';

export const Settings = forwardRef<HTMLElement, SettingsProps>((props, ref) => {
	if (__SERVER__) {
		if (getWillShowNav4()) {
			// @ts-expect-error - Type 'ReactNode' is not assignable to type 'string'.
			return <Nav4SkeletonSettingsButton label={props.tooltip} />;
		}
		// @ts-expect-error - Type 'ReactNode' is not assignable to type 'string'.
		return <SkeletonSettingsButton label={props.tooltip} />;
	}

	const buttonProps = {
		...props,
		ref,
	};

	return <AkSettings {...buttonProps} />;
});
