import React, { useMemo } from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import Tooltip from '@atlaskit/tooltip';
import { getAdminBaseUrl, WAC_URL } from '@atlassian/jira-external-urls';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	isEligibleForJsmJpdTrialAwarenessV2,
	isInVariationForJsmJpdTrialAwarenessV2,
	isEligibleForJswTrialAwarenessV2,
	isInVariationForJswTrialAwarenessV2,
} from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-trial-modal';
import { useLoggedInPage } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils';
import {
	getTrialDaysLeft,
	isInGracePeriod,
} from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils/src/utils';
import { FireUiAnalytics, ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { STANDARD_EDITION, PREMIUM_EDITION } from '@atlassian/jira-shared-types';
import {
	useCloudId,
	useEnvironment,
	useIsSiteAdmin,
	useLocale,
} from '@atlassian/jira-tenant-context-controller';
import { EATopNavAnimatedButtonContainer } from '../../../common/ui/styled';
import { BillingDetailsPopup, type TriggerProps } from './billing-details-popup';
import { productAbbreviations } from './constants';
import { Dropdown } from './dropdown';
import messages from './messages';
import { TriggerButton } from './trigger-button';
import type { TrialPillButtonProps } from './types';

export const TrialPillButton = ({
	productKey,
	projectType,
	edition,
	entitlementDetails,
	invoiceGroupId,
	transactionAccountId,
	isBillingAdmin,
}: TrialPillButtonProps) => {
	const { formatMessage } = useIntl();
	const locale = useLocale();
	const environment = useEnvironment();
	const cloudId = useCloudId();
	const isSiteAdmin = useIsSiteAdmin();
	const { trialEndTime, billingSourceSystem } = entitlementDetails;

	const currentDate = useMemo(() => Date.now(), []);
	const trialDaysLeft = getTrialDaysLeft(currentDate, trialEndTime);
	const inGracePeriod = isInGracePeriod(currentDate, trialEndTime);

	const isEnglishLocale = locale.startsWith('en');
	const isJswStandard = productKey === 'jira-software' && edition === STANDARD_EDITION;

	const isEligibleForJsmJpdTrialAwarenessV2Experiment =
		isEligibleForJsmJpdTrialAwarenessV2(projectType);

	const isJsmJpdTrialAwarenessV2ExperimentEnabled =
		isEligibleForJsmJpdTrialAwarenessV2Experiment && isInVariationForJsmJpdTrialAwarenessV2();

	const isEligibleForJswTrialAwarenessV2Experiment = isEligibleForJswTrialAwarenessV2(
		projectType,
		edition,
	);

	const isJswTrialAwarenessV2ExperimentEnabled =
		isEligibleForJswTrialAwarenessV2Experiment && isInVariationForJswTrialAwarenessV2();

	// I wanted to add a comment to this condition, but comment would be longer then the component itself so I gave up ...
	const jstoTarget =
		!!isBillingAdmin &&
		!inGracePeriod &&
		isSiteAdmin &&
		isJswStandard &&
		!isJswTrialAwarenessV2ExperimentEnabled;

	const rawJstoUrl = `${
		WAC_URL[environment]
	}/software/jira?ref=edition_awareness_standard_trial_${billingSourceSystem.toLowerCase()}&cloudSiteId=${cloudId}`;
	const jstoUrl = useLoggedInPage({ pageUrl: rawJstoUrl });

	const addBillingDetailsUrl =
		entitlementDetails?.billingSourceSystem === 'CCP'
			? `${getAdminBaseUrl()}/billing/${transactionAccountId}/${invoiceGroupId}/payment-flow?referrer=${productAbbreviations[productKey]}`
			: `${getAdminBaseUrl()}/s/${cloudId}/billing/paymentdetails/add`;

	const tooltipMessage = formatMessage(
		inGracePeriod ? messages.addPaymentDetailsTooltip : messages.trialButtonTooltip,
	);

	const analyticsAttributes = useMemo(
		() => ({
			edition,
			productKey,
			projectType,
			isEnglishLocale,
			entitlementDetails,
			transactionAccountId,
			invoiceGroupId,
			isBillingAdmin,
		}),
		[
			edition,
			entitlementDetails,
			invoiceGroupId,
			isEnglishLocale,
			productKey,
			projectType,
			transactionAccountId,
			isBillingAdmin,
		],
	);

	const triggerButtonProps = useMemo(
		() => ({
			trialDaysLeft,
			inGracePeriod,
			jstoUrl: jstoTarget ? jstoUrl : undefined,
			addBillingDetailsUrl,
			analyticsAttributes,
			productKey,
			projectType,
			edition,
		}),
		[
			trialDaysLeft,
			inGracePeriod,
			jstoTarget,
			jstoUrl,
			addBillingDetailsUrl,
			analyticsAttributes,
			productKey,
			projectType,
			edition,
		],
	);

	let content: null | JSX.Element = null;

	if (jstoTarget || inGracePeriod) {
		content = (
			<EATopNavAnimatedButtonContainer>
				<Tooltip content={tooltipMessage} position="bottom">
					{(tooltipProps) => (
						<TriggerButton
							{...triggerButtonProps}
							triggerProps={tooltipProps}
							// We must ensure isEligibleForJswTrialAwarenessModalV2 will always be false when inGracePeriod
							// but we still need to check it when jstoTarget is true as user might be in control group.
							isEligibleForJswTrialAwarenessModalV2={
								!inGracePeriod && isEligibleForJswTrialAwarenessV2Experiment
							}
						/>
					)}
				</Tooltip>
			</EATopNavAnimatedButtonContainer>
		);
	} else if (isJsmJpdTrialAwarenessV2ExperimentEnabled || isJswTrialAwarenessV2ExperimentEnabled) {
		content = (
			<EATopNavAnimatedButtonContainer>
				<TriggerButton
					{...triggerButtonProps}
					isEligibleForJsmJpdTrialAwarenessModalV2={isEligibleForJsmJpdTrialAwarenessV2Experiment}
					isEligibleForJswTrialAwarenessModalV2={isEligibleForJswTrialAwarenessV2Experiment}
				/>
			</EATopNavAnimatedButtonContainer>
		);
	} else {
		content = (
			<EATopNavAnimatedButtonContainer>
				<BillingDetailsPopup
					analyticsAttributes={analyticsAttributes}
					edition={edition}
					inGracePeriod={inGracePeriod}
					addBillingDetailsUrl={addBillingDetailsUrl}
				>
					{(triggerProps: TriggerProps) => (
						<TriggerButton
							{...triggerButtonProps}
							{...triggerProps}
							isEligibleForJsmJpdTrialAwarenessModalV2={
								isEligibleForJsmJpdTrialAwarenessV2Experiment
							}
							isEligibleForJswTrialAwarenessModalV2={isEligibleForJswTrialAwarenessV2Experiment}
						/>
					)}
				</BillingDetailsPopup>
			</EATopNavAnimatedButtonContainer>
		);
	}

	if (fg('demonstrating_value_of_jira_premium_features_gate')) {
		const isSupportedStandard = edition === STANDARD_EDITION;
		const isSupportedPremium =
			edition === PREMIUM_EDITION &&
			(productKey === 'jira' || productKey === 'jira-core' || productKey === 'jira-software');
		const isSupportedEdition = isSupportedStandard || isSupportedPremium;
		const shouldShowEditionAwarenessDropdown = isSupportedEdition && !inGracePeriod;
		const [config] = UNSAFE_noExposureExp('demonstrating_the_value_of_jira_premium_features');
		const isTest = config.get('cohort', 'not-enrolled') === 'variant';

		if (shouldShowEditionAwarenessDropdown && isTest) {
			content = (
				<Dropdown
					applicationEdition={edition}
					productKey={productKey}
					status="default"
					analyticsAttributes={analyticsAttributes}
				>
					<FormattedMessage
						id="navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.days-left-button"
						defaultMessage="{trialDaysLeft, plural, one {# day} other {# days}} left"
						description="Trial information button title showing how many days is left in trial"
						values={{
							trialDaysLeft,
						}}
					/>
				</Dropdown>
			);
		}
	}

	if (content !== null) {
		return (
			<ContextualAnalyticsData attributes={{ isSiteAdmin, isBillingAdmin }}>
				<SpotlightTarget name="edition-awareness-trial-pill">
					{content}
					<FireUiAnalytics
						action="viewed"
						actionSubject="button"
						actionSubjectId="editionAwarenessTrialPill"
						attributes={analyticsAttributes}
					/>
				</SpotlightTarget>
			</ContextualAnalyticsData>
		);
	}
	return null;
};
