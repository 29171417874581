import { IntegratedChoreographerMessageTypesEnum } from './constants';
import type {
	ChoreographedMessage,
	ChoreographedMessageTypes,
	MaybeChoreographedComponentProps,
} from './types';

export function shouldBeChoreographed(
	props?: MaybeChoreographedComponentProps,
): props is ChoreographedMessage {
	return (
		!!props?.messageType &&
		IntegratedChoreographerMessageTypesEnum.includes(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			props.messageType as ChoreographedMessageTypes,
		)
	);
}
