import {
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	type UserSegmentationTeamTypes,
} from '../../utils/user-segmentation';

export const ELIGIBLE_TEAM_TYPES_M2_FULL_NOTATION: UserSegmentationTeamTypes[] = [
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
];
